import React, { useEffect } from "react";
import CustomNavbar from "../../components/Navbar";
import Footer from "../../components/layout/Footer";
import SectionFive from "../MainPage/Sections/SectionFive";
import { Container } from "react-bootstrap";
import styles from "../../css/About/about.module.css";

const Walkin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CustomNavbar />
      <div className={`p-2 text-center  ${styles.text_wrapper}`}>
        <div className={styles.bgImage}></div>
        <Container className="p-4 d-flex flex-column align-items-center ">
          <div className={styles.innerCont}>
            <div className={styles.heading}>Walk-in clinic and Urgent Care</div>
            <p>
              Fast, high-quality assistance for injuries and illnesses that are
              not life-threatening. From common colds and coughs to minor
              sprains and strains, our healthcare services prioritize
              convenience. We offer urgent care with the ease of online
              check-ins, no appointment requirements, and acceptance of OHIP and
              insurance plans.
            </p>
            <p className="mb-5">
              Our Toronto walk-in clinic and urgent care, located at 2700
              Dufferin Street, Unit 56, is committed to revolutionizing
              healthcare by providing innovative, patient-centric solutions for
              various medical conditions. Our state-of-the-art clinics and
              experienced healthcare professionals ensure patients receive
              prompt and personalized care, contributing to a healthier and
              happier community.
            </p>

            <a
              className={` rounded-2 text-white ${styles.startedbtn}`}
              target="blank"
              href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=fee48727-4828-402b-8293-103f7f1a87d0#/online-booking"
            >
              Get Started Now
            </a>
          </div>
        </Container>
      </div>
      <div className="px-sm-5 px-2 ">
        <SectionFive />
      </div>
      <Footer />
    </div>
  );
};

export default Walkin;
