import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../css/MainPage/main.module.css";
import SocialLinks from "../../components/MainPage/SocialLinks";
import Navbar from "../../components/Navbar";
import Footer from "../../components/layout/Footer";
import SectionFive from "./Sections/SectionFive";
import SectionOne from "./Sections/SectionOne";
import SectionTwo from "./Sections/SectionTwo";
import SectionThree from "./Sections/SectionThree";
import DocxReader from "../../components/DocxReader";

const DetailPage = (props) => {
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("state", state);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Container fluid className={styles.contWrapper}>
        {/* Social links starts here */}
        {/* <SocialLinks social /> */}
        {/* NavBar  here */}
        <Navbar to="home" />
      </Container>
      {/*Sections starts here here */}
      <SectionOne data={state} />
      <SectionTwo />
      <SectionThree />
      <div className={styles.sectionfive_wrapper}>
        <SectionFive />
      </div>
      <Footer />
    </div>
  );
};

export default DetailPage;
