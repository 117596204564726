import React from "react";
import styles from "./footer.module.css";
import { footer_menu, navbar, sociallogo } from "../../../helper/Helper";
import { Col, Row } from "react-bootstrap";
import SocialLinks from "./SocialLinks";
import { getAuth, signOut } from "firebase/auth";
import app from "../../../env";
import { Link } from "react-router-dom";

const Footer = () => {
  const pages = [
    { title: "About CareXPS", path: "" },
    { title: "Home Health Care", path: "" },
    { title: "Walk-in clinic and Urgent Care", path: "" },
  ];
  return (
    <div className={styles.footerSection}>
      <div className={styles.footerFirstRow}>
        <Row className="d-flex align-items-center">
          <Col md="6">
            <div
              className={`mt-3 ${styles.footerLogo}`}
              style={{
                backgroundImage: `url(${require("../../../assets/Logo-01.png")})`,
              }}
            />
            <div className="d-flex align-items-center gap-2 gap-md-3 mt-4 mb-5 flex-wrap">
              {navbar?.map((item, index) => {
                if(index == 3) return
                return (

                  <Link to={item?.to} key={index} className={styles.footer_menu_items}>
                    {item?.title}
                  </Link>
                );
              })}
            </div>
          </Col>
          <Col md="6" className="d-flex flex-column align-items-md-end align-items-start mb-3 mb-md-0 ">
            <div>
              <p className={styles.updatesText}>
                Get the latest updates about CareXPS
              </p>
              <input type="email" placeholder="Email Address" className={`rounded-1 me-2 px-2 mt-2 ${styles.emailInput}`} />
              <button className={`mt-2 ${styles.signup_btn}`}>Sign Up</button>
            </div>
          </Col>
        </Row>
      </div>
      <Row className={styles.footerSectionInnerCont}>
        <Col sm="4" className={`d-flex justify-content-center justify-content-sm-start  ${styles.footerCol1}`}>
          <SocialLinks social />
        </Col>

        <Col sm="4" className={styles.footerCol2}>
          <img
            src={require("../../../assets/leaf.png")}
            className={styles.footerCol2Logo}
          />
        </Col>
        <Col sm="4" className={styles.footerCol3}>
          <Link to="#" className={styles.menuItem}>
            Terms of Services
          </Link>
          <Link to="#" className={styles.menuItem}>
            Privacy Policy
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
