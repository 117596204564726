import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./footer.module.css";
import { sociallogo } from "../../../helper/Helper";

const SocialLinks = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.socialBtnRow}>
       
        {sociallogo?.map((item, index) => {
          return (
            <div key={index} md="1" className={styles.socialBtn}>
              <img className={styles.socialIcon} src={item} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SocialLinks;
