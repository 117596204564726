import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "./sectionone.module.css";
import { Col, Row } from "react-bootstrap";
import { camelize } from "../../helper/Helper";
import { GoDotFill } from "react-icons/go";

const SectionOneContent = (props) => {
  let { norisk, title, data, hide0, symptoms, seperateTitle } = props;
  let benefitData = data?.benefits;
  let reasonsData = data?.reasons;

  if (norisk) {
    data["risks"] = [];
  }
  function capitalizeFirstLetter(string) {
    let num = string?.slice(0, 2);
    let text = string?.slice(2)?.replace(" ", "");
    return num + " " + text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <div className={`${styles.contentWrapper}`}>
        {symptoms?.length > 0 && (
          <div className={` ${styles.heading24}`}>
            {`${
              seperateTitle || data?.reasonText
                ? "Reasons Of " + title
                : "Symptoms Of " + title
            }`}
          </div>
        )}

      {data?.reasonText && (
        <div
          className={styles.clauseParagraphText}
          style={{ marginBottom: 20 }}
        >
          {data?.reasonText}
        </div>
      )}
      {data?.introText && (
        <div className={styles.paragraphOrderText} style={{ marginBottom: 20 }}>
          {data?.introText}
        </div>
      )}

      {symptoms?.length == 0 &&
        data?.slugText != null &&
        data?.causes?.length == 0 && (
          <div className={`ps-sm-2 ${styles.paragraphOrderText}`}>
            {data?.slugText}
          </div>
        )}

      {symptoms?.map((item, index) => {
        if (hide0 && index == 0) {
          return;
        }
        return (
          <div key={index} className={`pl-sm-2 ${styles.paragraphOrderText}`}>
            <div className={styles.dot}>
              <GoDotFill size={"10px"} />
            </div>
            {item}
          </div>
        );
      })}
      {benefitData?.length > 0 && (
        <div className={` ${styles.heading24}`}>Benefits of {title}</div>
      )}
      {benefitData?.map((item, index) => {
        return (
          <div key={index} className={`pl-sm-2 ${styles.paragraphOrderText}`}>
            <div className={styles.dot}>
              <GoDotFill size={"10px"} />
            </div>
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default SectionOneContent;
