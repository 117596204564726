import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import MainPage from "./Pages/MainPage";
import DetailPage from "./Pages/DetailPage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import app from "./env";
import ResetPassword from "./Pages/ResetPassword";
import About from "./Pages/About";
import HomeCare from "./Pages/HomeCare";
import Walkin from "./Pages/Walkin";
import Contactus from "./Pages/Contactus";
import Loader from "./components/Loader";

function App() {
  const [getUser, setGetUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const stateChange = () => {
    setLoading(true);
    try {
      const auth = getAuth(app);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          setLoading(false);

          setGetUser(true);
        } else {
          setGetUser(false);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    stateChange();
  }, []);
  if (loading) {
    return <Loader/>;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/reset" element={<ResetPassword />}></Route>
        <Route path="/detailPage" element={<DetailPage />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/homehealthcare" element={<HomeCare />}></Route>
        <Route path="/walkin" element={<Walkin />}></Route>
        <Route path="/contact_us" element={<Contactus />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
