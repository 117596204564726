import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../css/components.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SignInWithGoogle } from "../../helper/Helper";

const GoogleButton = () => {
  const navigate = useNavigate();
  return (
    <Row className={styles.googleBtnWrapper}>
      <Col className={styles.googleBtn}>
        <img
          className={styles.google_logo}
          src={require("../../assets/google1.png")}
          alt=""
        />
        <div
          onClick={() => SignInWithGoogle(navigate)}
          className={styles.googleBtnText}
        >
          Sign up with Google
        </div>
      </Col>
    </Row>
  );
};

export default GoogleButton;
