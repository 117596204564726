import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../css/Signup/signup.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import GoogleButton from "../../components/Buttons/GoogleButton";
import { SignUp } from "../../helper/Helper";
import TextInput from "../../components/TextInputs/TextInput";
import app from "../../env";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [signupForm, setSignupForm] = useState({
    email: "",
    username: "",
    password: "",
    term: false,
  });
  const navigate = useNavigate();
  const handleSignupForm = (e) => {
    const { name, value, type } = e.target;
    setSignupForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? !signupForm?.term : value,
    }));
  };

  const auth = getAuth(app);

  const reateUser = (email, password) => {
    try {
      setLoading(true)
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          navigate("/");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          let errMsg=error?.message
          alert(errMsg);
        });
    } catch (error) {
      setLoading(false);
      let errMsg=error?.message
      alert(errMsg);
    }
  };

  const onSignUp = () => {
    const { email, username, password, term } = signupForm;
    if (email !== "" && username !== "" && password !== "") {
      if (term == true) {
        reateUser(email, password);
      } else {
        alert("agree to terms & conditions");
      }
    } else {
      alert("Fill All details");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row className={styles.logoWrapper}>
          <Col xs={12}>
            <div className={styles.logoImg}></div>
          </Col>
        </Row>
        <Stack gap={3} className={styles.headingStack}>
          <div className={styles.headingText}>Create free account</div>
          <div className={styles.sloganText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            <br className="brDesktop" /> Turpis morbi pulvinar venenatis non.
          </div>
        </Stack>
        {/* Google Sign Up button component */}
        <GoogleButton />
        <div className={styles.seperator_lines}>
          <div className={styles.line} />
          <p>or</p>
          <div className={styles.line} />
        </div>
        {/* Form TextFieelds Starts here */}
        <div className={styles.formStack}>
          {SignUp?.map((item, index) => {
            return (
              <TextInput
                key={index}
                type={item?.type}
                placeholder={item?.placeholder}
                label={item?.label}
                name={item?.name}
                onChange={handleSignupForm}
              />
            );
          })}
        </div>
        <div className={styles.signupButtonWrapper}>
          <div className={styles.checkbox_wrapper}>
            <input
              type="checkbox"
              name="term"
              onChange={handleSignupForm}
              checked={signupForm?.term}
            />
            <p>
              I agree to the <Link to="#">Terms & Conditions</Link>
            </p>
          </div>
          <button className={styles.SignUpButton} onClick={onSignUp}>
            {loading?"Signing Up...":"Sign up"}
          </button>
          <div className={styles.directionalText}>
            Already have an account? <Link to="/login">Login now</Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Signup;
