import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/MainPage/main.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { sectionThreeContent } from "../../../helper/Helper";

const SectionThree = () => {
  const videoRef = useRef(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const playVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const ContentItem = ({ data }) => {
    return (
      <div className={styles.section3Item}>
        <div className={styles.sectionThreeItemIconWrapper}>
          <img
            className={styles.sectionThreeItemIcon}
            src={data?.icon}
            alt=""
          />
        </div>
        <div className={styles.sectionThreeItemContentWrapper}>
          <div className={styles.sectionThreeItemTitle}>{data?.title}</div>
          <div className={styles.sectionThreeItemSlug}>{data?.slug}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.sectionThreeContainer}>
      <div className={styles.textureWrapper2} />
      <div className={styles.sectionThreeInnerCont}>
        <div className={styles.sectionThreeTitle}>
          About CareXPS
        </div>
        <Row className={styles.sectionThreeContentRow}>
          <Col md="6" className={styles.sectionThreeContentCol}>
            {sectionThreeContent?.map((item, index) => {
              return <ContentItem key={index} data={item} />;
            })}
            <div className={styles.section3BUttonWrapper}>
              <button className={styles.gsnButton2}>Get Started Now</button>
            </div>
          </Col>
          <Col md="6" className={styles.sectionThreeVideoCol}>
            {!isPlaying && (
              <div onClick={playVideo} className={styles.playButton}>
                <img
                  src={require("../../../assets/play.png")}
                  className={styles.playIcon}
                />
              </div>
            )}
            <video
              onTouchEnd={playVideo}
              onPause={() => setIsPlaying(false)}
              onPlay={() => setIsPlaying(true)}
              ref={videoRef}
              preload="auto"
              className={styles.section3Video}
              controls
            >
              <source src={require("../../../assets/video2.mp4")} />
            </video>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SectionThree;
