import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/DetailPage/main.module.css";
import { Col, Row } from "react-bootstrap";
import { howItWorksContent } from "../../../helper/Helper";

const SectionThree = () => {
  const videoRef = useRef(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const playVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className={styles.SectionThree}>
      <div className={`p-sm-5 p-3 ${styles.SectionThreeInnerCont}`}>
      <div className={styles.textureWrapper2} />

        <div className={`text-center ${styles.SectionThreeCol1Title}`}>About CareXPS</div>
        <div className={styles.SectionThreeCol1BulletWrapper}>
          {howItWorksContent?.map((item, index) => {
            return (
              <div key={index} className={`mt-sm-5 mt-4 ${styles.SectionThreeCol1Bullet}`}>
                <div className={styles.SectionThreeCol1BulletNum}>
                 <img src={item.img}  alt="" className={styles.bulletsImages}/>
                </div>
                <div >
                  <h5 className={`fw-normal ${styles.SectionThreeCol1BulletText}`}>

                  {item?.title}
                  </h5>
                  <p className={styles.SectionThreeCol1Bulletdescrip}>
                    {item?.description}
                  </p>

                </div>
              </div>
            );
          })}
        </div>
        <div className={`mt-sm-5 mt-3 ${styles.SectionThreeCol2VideoWrapper}`}>
          {!isPlaying && (
            <div onClick={playVideo} className={styles.playButton}>
              <img
                src={require("../../../assets/play.png")}
                className={styles.playIcon}
              />
            </div>
          )}
          <video
            onTouchEnd={playVideo}
            onPause={() => setIsPlaying(false)}
            onPlay={() => setIsPlaying(true)}
            ref={videoRef}
            preload="auto"
            className={styles.SectionThreeCol2Video}
            controls
            // poster={require("../../../assets/videoThumbnail2.png")}
          >
            <source src={require("../../../assets/video2.mp4")} />
          </video>
        </div>

      </div>
    </div>
  );
};

export default SectionThree;
