import React from "react";
import './loader.css'

const Loader = () => {
  return (
    <div className="banter-loader">
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
  </div>
  );
};

export default Loader;
