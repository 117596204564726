import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/DetailPage/main.module.css";
import { Col, Row } from "react-bootstrap";
import SectionOneContent from "../../../components/DetailPage/SectionOneContent";
import SectionTwo from "./SectionTwo";
import Causes from "../../../components/DetailPage/Causes";
import CommonMedical from "../../../components/DetailPage/CommonMedical";
import { camelize, detailPageContent } from "../../../helper/Helper";
import { treatment_list } from "../../../helper/Helper";
const SectionOne = ({ data }) => {
  const [content, setContent] = useState(null);
  const [treatmentShortData, setTreatmentShortData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let pageData = detailPageContent?.filter((e) => {
      return e?.title?.toLowerCase() == data?.name.toLowerCase();
    });
    treatment_list?.map((item, index) => {
      let _d = item?.items?.filter((e) => {
        return e?.name?.toLowerCase() == data?.name.toLowerCase();
      });
      if (_d?.length > 0) {
        setTreatmentShortData(_d[0]);
      }
    });
    if (pageData?.length > 0) {
      setContent(pageData[0]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className={styles.SectionOne}>
      <div className={styles.SectionOneInnerCont}>
        <Row className={styles.sectionOneRow}>
          <Col lg="12" className={styles.sectionOneCol1}>
            <div className={styles.sectionOneCol1Title}>
              <div className={styles.sectionOneCol2ImageWrapper}>
                <img
                  src={treatmentShortData?.icon}
                  className={styles.sectionOneCol2Img}
                />
              </div>
              <div style={{ flex: 1 }}>
                {camelize(content?.title)}
                <div
                  className={styles.sectionOneCol1Details}
                  style={{ color: "#6467CE" }}
                >
                  OHIP Eligible
                </div>
              </div>
              <div className={styles.section3BUttonWrapper}>
                <button className={styles.gsnButton2}>
                  <a
                    target="blank"
                    href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=fee48727-4828-402b-8293-103f7f1a87d0#/online-booking"
                  >
                    Book an Appointment
                  </a>
                </button>
              </div>
            </div>

            <div
              className={`px-sm-4 py-sm-3 mt-sm-4 ${styles.sectionOneCol1Instructions}`}
            >
              <SectionOneContent
                title={camelize(content?.title)}
                symptoms={content?.symptoms}
                introText={content?.introText}
                data={content}
              />
            </div>
          </Col>
          {/* <Col lg="4" className={styles.sectionOneCol2}>
            <div className={styles.section3BUttonWrapper}>
              <button className={styles.gsnButton2}>
                <a
                  target="blank"
                  href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=fee48727-4828-402b-8293-103f7f1a87d0#/online-booking"
                >
                  Book an Appointment
                </a>
              </button>
            </div>
          </Col> */}
        </Row>

        {content?.risks?.length > 0 && (
          <Causes data={content?.risks} title="Risks" />
        )}
        {content?.suggestions?.length > 0 && (
          <Causes data={content?.suggestions} />
        )}
        {content?.joiningDetails?.length > 0 && (
          <Causes
            data={content?.joiningDetails}
            title="Joining CareXPS: Your Pathway to Mental Well-being in 4 Steps "
          />
        )}
        {content?.mental_Treatments?.length > 0 && (
          <Causes
            data={content?.mental_Treatments}
            title="Common Pharmacologic Treatments"
          />
        )}
        {content?.slugText1 && (
          <div className={styles.clauseParagraphText}>{content?.slugText1}</div>
        )}

        {content?.info?.length > 0 && (
          <Causes
            data={content?.info}
            title="Epigenetic-Driven Nutritional Psychiatry at CareXPS"
          />
        )}

        {content?.suggestionsCost?.length > 0 && (
          <Causes data={content?.suggestionsCost} />
        )}

        {content?.medications?.length > 0 && (
          <Causes
            data={content?.medications}
            title="Commonly Used Weight Loss Medications"
          />
        )}

        {content?.causes?.length > 0 && (
          <Causes data={content?.causes} title="Causes" />
        )}
        {content?.symptoms2 && (
          <div
            className={`px-sm-4 py-sm-3 mt-sm-4 ${styles.sectionOneCol1Instructions}`}
          >
            <SectionOneContent
              norisk
              seperateTitle={camelize(content?.symptoms2[0])}
              title={camelize(content?.symptoms2[0])}
              symptoms={content?.symptoms2}
              data={content}
              hide0
            />
          </div>
        )}

        {content?.risks2?.length > 0 && (
          <Causes data={content?.risks2} title="Risks" />
        )}
        {content?.reasons?.length > 0 && (
          <Causes data={content?.reasons} title="Reasons" />
        )}
        {content?.afterReasonText1 && (
          <div className={styles.clauseParagraphText}>
            {content?.afterReasonText1}
          </div>
        )}
        {content?.beforeTreatmentText && (
          <div className={styles.clauseParagraphText}>
            {content?.beforeTreatmentText}
          </div>
        )}
        {content?.causes2?.length > 0 && (
          <Causes data={content?.causes2} title="Causes" />
        )}
        {content?.treatments2?.length > 0 && (
          <CommonMedical
            seperateTitle={camelize(content?.treatments2[0])}
            data={content?.treatments2}
          />
        )}
        {content?.symptoms3 && (
          <SectionOneContent
            norisk
            seperateTitle={camelize(content?.symptoms3[0])}
            title={camelize(content?.symptoms3[0])}
            symptoms={content?.symptoms3}
            data={content}
            hide0
          />
        )}
        {content?.symptoms4 && (
          <SectionOneContent
            norisk
            seperateTitle={camelize(content?.symptoms4[0])}
            title={camelize(content?.symptoms4[0])}
            symptoms={content?.symptoms4}
            data={content}
            hide0
          />
        )}
        {content?.symptoms5 && (
          <SectionOneContent
            norisk
            seperateTitle={camelize(content?.symptoms5[0])}
            title={camelize(content?.symptoms5[0])}
            symptoms={content?.symptoms5}
            data={content}
            hide0
          />
        )}
        {content?.causes3?.length > 0 && (
          <Causes data={content?.causes3} title="Causes" />
        )}
        {content?.afterCause && (
          <div className={styles.clauseParagraphText}>
            {content?.afterCause}
          </div>
        )}
        {content?.symptoms?.length > 0 && content?.slugText && (
          <div className={styles.clauseParagraphText}>{content?.slugText}</div>
        )}
        {content?.treatments?.length > 0 && !content?.treatments2 && (
          <CommonMedical
            data={content?.treatments}
            seperateTitle={camelize(
              typeof content?.treatments[0] == "string"
                ? content?.treatments[0]
                : false
            )}
          />
        )}
        {content?.slugText2 && (
          <div className={styles.clauseParagraphText}>{content?.slugText2}</div>
        )}
        {content?.treatments?.length > 0 && content?.treatments2 && (
          <CommonMedical
            seperateTitle={camelize(content?.treatments[0])}
            data={content?.treatments}
          />
        )}

        {content?.treatments3?.length > 0 && (
          <CommonMedical
            seperateTitle={camelize(content?.treatments3[0])}
            data={content?.treatments3}
          />
        )}
        {content?.symptoms?.length > 0 && content?.slugTextBottom && (
          <div className={styles.clauseParagraphText}>
            {content?.slugTextBottom}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionOne;
