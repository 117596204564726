import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/Login/login.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import GoogleButton from "../../components/Buttons/GoogleButton";
import { SignIn } from "../../helper/Helper";
import TextInput from "../../components/TextInputs/TextInput";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const reset = () => {
    if (email != "") {
      setLoading(true);
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSuccessMsg("We have sent you an email. Check your email.");
          setEmail("");
          setLoading(false);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setSuccessMsg(errorMessage+email);
          alert("Something Went Wrong");
          setLoading(false);
        });
    } else {
      alert("Please enter email.");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row className={styles.logoWrapper}>
          <Col xs={12}>
            <div className={styles.logoImg}></div>
          </Col>
        </Row>
        <Stack gap={3} className={styles.headingStack}>
          <div className={styles.headingText}>Recover Password</div>
          <div className={styles.sloganText}>
            {successMsg?.length > 0 ? (
              successMsg
            ) : (
              <>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br className="brDesktop" /> Turpis morbi pulvinar venenatis
                non.
              </>
            )}
          </div>
        </Stack>

        {/* Form TextFieelds Starts here */}
        <div className={styles.formStack}>
          <TextInput
            type={"email"}
            placeholder={"Enter email"}
            label={"Email"}
            name={"item?.name"}
            onChange={(e)=>setEmail(e.target.value)}
          />
          <button className={styles.SignUpButton} onClick={() => reset()}>
            {loading?"Wait..":"Send"}
          </button>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
