import React, { useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/MainPage/main.module.css";
import { discriptions_list, featured_items } from "../../../helper/Helper";
import { Container } from "react-bootstrap";

const InfoSection = () => {
  return (
    <div className={styles.infoSection}>
      <Row className={styles.infoSectionInner}>
        {discriptions_list?.map((item, index) => {
          return (
            <Col lg="3" className={styles.section1BottomWrapperCol} key={index}>
              <div className={styles.infoIconContainer}>
                <div className={styles.infoIconWrapper}>
                  <img
                    src={item?.icon}
                    className={styles.infoIcon}
                  />
                </div>
                <div className={styles.bookTextWrapper}>
                  <div className={styles.bookText}>{item?.heading}</div>
                  <div className={styles.bookDescText}>{item?.paragraph}</div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default InfoSection;
