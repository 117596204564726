import React from "react";
import styles from "../../css/components.module.css";
import { Link } from "react-router-dom";

const TextInput = (props) => {
  return (
    <div className={styles.input_wrapper}>
      <label className={styles.Input_label} {...props}>
        {props?.label}
      </label>
      {props?.type == "password" && (
        <span className={styles.forgotPass} {...props}>
          <Link to="/reset">Forgot Password?</Link>
        </span>
      )}
      <input {...props} />
    </div>
  );
};

export default TextInput;
