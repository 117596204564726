import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "./sectionone.module.css";
import { Col, Row } from "react-bootstrap";
import { sectionFourQuestions, whatToExpectContent } from "../../helper/Helper";
import SectionFive from "../../Pages/MainPage/Sections/SectionFive";
import Accordion from "../MainPage/Accordion";

const CommonMedical = (props) => {
  let { data, seperateTitle, hide0 } = props;

  return (
    <div className={`my-sm-4 ${styles.commonMedicalWrapper}`}>
      <div className={styles.commonMedicalHeading}>
        {seperateTitle || `Common Medical Treatments`}
      </div>
      <div className={styles.faqsWrapper}>
        {data?.map((item, index) => {
          if ((index == 0 && hide0) || (index == 0 && seperateTitle)) return;
          return <Accordion key={index} data={item} id={index} />;
        })}
      </div>
    </div>
  );
};

export default CommonMedical;
