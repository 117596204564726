import React, { useEffect } from "react";
import CustomNavbar from "../../components/Navbar";
import Footer from "../../components/layout/Footer";
import SectionFive from "../MainPage/Sections/SectionFive";
import { Container } from "react-bootstrap";
import styles from "../../css/About/about.module.css";

const About = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div>
      <CustomNavbar />
      <div className={`p-2 text-center  ${styles.text_wrapper}`}>
        <div className={styles.bgImage}></div>
        <Container className="p-4 d-flex flex-column align-items-center ">
          <div className={styles.innerCont}>
            <div className={styles.heading}>
              Welcome to Care XPS Medicine Your Trusted Comprehensive Medicine
              Innovators
            </div>
            <p>
              At Care XPS Medicine, we are a team of digital health and data
              driven medical providers committed to innovative comprehensive
              medicine services to the residents of Ontario, Canada. Our
              experienced team of healthcare professionals is dedicated to
              offering preventive medicine, chronic disease management, and
              comprehensive care for all ages.
            </p>
            <p>
              We understand that every patient is unique, and we strive to build
              long-lasting relationships based on trust and open communication.
              Whether you require a house call, telemedicine, or prefer an
              in-office visit, our compassionate and skilled healthcare
              providers are here to address your medical needs
            </p>
            <p className="mb-5">
              To become a registered patient with Care XPS Medicine, simply
              complete our patient enrollment and consent form for you and your
              family. Once enrolled, you can easily choose your appointment date
              for a house call, telemedicine visit, or an in-office visit.
              Experience the convenience and personalized care that our care
              team of providers offer.
            </p>
            <a
              className={` rounded-2 text-white ${styles.startedbtn}`}
              target="blank"
              href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=fee48727-4828-402b-8293-103f7f1a87d0#/online-booking"
            >
              Get Started Now
            </a>
          </div>
        </Container>
      </div>
      <div className="px-sm-5 px-2 ">
        <SectionFive />
      </div>
      <Footer />
    </div>
  );
};

export default About;
