import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/MainPage/main.module.css";
import { Col, Container, Row } from "react-bootstrap";

const SectionFour = () => {
  return (
    <div id="Help / FAQ" className={styles.sectionFourContainer}>
      <Container>
        <Row className={styles.sectionFourRow}>
          <Col md="6" className={styles.sectionFourCol1}>
            <div className={styles.sectionFourCol1Title}>
              Available in Ontario
            </div>
            <div className={styles.sectionFourCol1Slug}>
              See if we are available in your province.
            </div>
          </Col>
          <Col md="6" className={styles.sectionFourCol2}>
            <div className={styles.sectionFourPickerWrapper}>
              <div className={styles.sectionFourPickerLabel}>Province</div>
              <div className={styles.section4SelectorWrapper}>
                <select className={styles.section4Selector}>
                    <option>Where are you today?</option>
                    <option>Ontario</option>
                </select>
              </div>
            </div>
            <div className={styles.sectionFourButtonWrapper}>
              <button className={styles.sectionFourButton}>
                Check availability
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectionFour;
