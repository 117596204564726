import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/MainPage/main.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "../../../components/MainPage/Accordion";
import { sectionFourQuestions } from "../../../helper/Helper";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";

const SectionFive = () => {
  return (
    <div className={styles.sectionFiveContainer}>
      <div className={styles.sectionFiveInnerCont}>
        <div className={styles.sectionfiveTitle}>
          Frequently asked questions
        </div>
        {sectionFourQuestions?.map((item, index) => {
          return <Accordion key={index} data={item} id={index} />;
        })}
      </div>
    </div>
  );
};

export default SectionFive;
