import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./navbar.module.css";
import { navbar } from "../../helper/Helper";
import { HashLink } from "react-router-hash-link";
import { FiLogOut } from "react-icons/fi";
import { getAuth, signOut } from "firebase/auth";
import app from "../../env";
import { useNavigate } from "react-router";

const CustomNavbar = ({ to }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();

  const _logout = () => {
    const auth = getAuth(app);
    try {
      signOut(auth)
        .then(() => {
          navigate("/");
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Navbar
      key="md"
      expand="md"
      className={`${styles.navCont} bg-body-tertiary `}
    >
      <Container fluid className={styles.navInnerContainer}>
        <Navbar.Brand href="#" className={styles.brandLogo}>
          <div>
            <img
              className={styles.logoImg}
              src={require("../../assets/Logo-01.png")}
              alt=""
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          className={styles.toggleBtn}
          aria-controls={`offcanvasNavbar-expand-md`}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <div>
                <img
                  className={styles.logoImg2}
                  src={require("../../assets/Logo-01.png")}
                  alt=""
                />
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className={styles.menuWrapper}>
            <Nav className="justify-content-center flex-grow-1 pe-3">
              <div className={styles.centerActions}>
                {navbar?.map((item, index) => {
                  let prod = `${item?.to}`;
                  let dev = "/#" + item?.to;
                  return (
                    <div className={styles.navBarItem}>
                      <HashLink
                        to={prod}
                        className={styles.navBarItem}
                        key={index}
                      >
                        {item?.title}
                      </HashLink>
                    </div>
                  );
                })}
              </div>
              <div className={styles.rightActions}>
                {!user && (
                  <HashLink
                    md="1"
                    className={styles.loginText}
                    onClick={() => user && _logout()}
                    to={!user ? "/login" : ""}
                  >
                    <span className={styles.logoutText}>Login</span>
                  </HashLink>
                )}
                {!user && (
                  <HashLink
                    md="1"
                    className={styles.signupButton}
                    onClick={() => user && _logout()}
                    to={!user ? "/login" : ""}
                  >
                    <span className={styles.signupText}>Sign up</span>
                  </HashLink>
                )}
                <div md="1" className={styles.langIconWrapper}>
                  <span className={styles.langText}>CA</span>
                  <img
                    className={styles.langIcon}
                    src={require("../../assets/langIcon.png")}
                    alt=""
                  />
                </div>
                {user && (
                  <HashLink
                    md="1"
                    className={styles.profileCircle}
                    onClick={() => user && _logout()}
                    to={!user ? "/login" : ""}
                  >
                    <img
                      className={styles.userIcon}
                      src={require("../../assets/userIcon.png")}
                      alt=""
                    />
                  </HashLink>
                )}
              </div>
            </Nav>
            {/* <button className={styles.exploreButton}>Explore Treatments</button> */}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
