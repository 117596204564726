import React, { useEffect } from "react";
import CustomNavbar from "../../components/Navbar";
import Footer from "../../components/layout/Footer";
import SectionFive from "../MainPage/Sections/SectionFive";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../../css/contactus/contactus.module.css";
import { useNavigate } from "react-router";
import { contact_details } from "../../helper/Helper";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const position = [43.71366, -79.3068]; // Default to San Francisco

  const uberIcon = new L.Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/25/25613.png", // Replace with your Uber-like marker icon
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [0, -48],
  });

  return (
    <div>
      <CustomNavbar />
      <div className={`p-5 fs-6  ${styles.text_wrapper}`}>
        <Container className="position-relative">
          <Row>
            <Col md="6">
              <p className={` text-uppercase ${styles.contactus_text}`}>
                contact us
              </p>
              <h3>Reach out to us</h3>
              <p>Get in Touch for Expert Medical Assistance.</p>
            </Col>
            <Col md="6" className="mt-3 mt-md-0">
              {contact_details?.map((item, index) => {
                return (
                  <div key={index}>
                    <h6>{item?.title}:</h6>
                    <p className="mt-2 mb-4">{item?.details}</p>
                  </div>
                );
              })}
            </Col>
          </Row>
          <div style={{ height: "10vh" }}></div>
        </Container>
      </div>
      <Container className={styles.map}>
        <MapContainer
          center={position}
          zoom={15}
          style={{ width: "100%", height: "100%", borderRadius: "10px" }}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={uberIcon} />
        </MapContainer>
      </Container>
      <div className="px-sm-5 px-5 ">
        <SectionFive />
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;
