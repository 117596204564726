import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../css/MainPage/main.module.css";
import SocialLinks from "../../components/MainPage/SocialLinks";
import Navbar from "../../components/Navbar";
import SectionOne from "./Sections/SectionOne";
import SectionTwo from "./Sections/SectionTwo";
import SectionThree from "./Sections/SectionThree";
import SectionFour from "./Sections/SectionFour";
import SectionFive from "./Sections/SectionFive";
import Footer from "../../components/layout/Footer";
import InfoSection from "./Sections/InfoSection";

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Container fluid className={styles.contWrapper}>
        {/* Social links starts here */}
        {/* <SocialLinks social /> */}
        {/* NavBar  here */}
        <Navbar to="" />
      </Container>
      {/*Sections starts here here */}
      <SectionOne />
      <InfoSection />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <div className="px-sm-5 px-2 ">
        <SectionFive />
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;
