import React, { useEffect } from "react";
import CustomNavbar from "../../components/Navbar";
import Footer from "../../components/layout/Footer";
import SectionFive from "../MainPage/Sections/SectionFive";
import { Container } from "react-bootstrap";
import styles from "../../css/About/about.module.css";

const HomeCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CustomNavbar />
      <div className={`p-2 text-center  ${styles.text_wrapper}`}>
        <div className={styles.bgImage}></div>
        <Container className="p-4 d-flex flex-column align-items-center ">
          <div className={styles.innerCont}>
            <div className={styles.heading}>Doctor Home Visits</div>
            <p>
              Care XPS Medicine is a pioneering healthcare company committed to
              delivering comprehensive medical care directly to the homes of
              physically enfeebled and frail elderly patients, ensuring comfort
              and convenience.
            </p>
            <p>
              With a dedicated team of compassionate professionals, Care XPS
              leverages innovative tools such as AI and data-driven digital
              technology to enhance the quality of care provided. Through
              personalized house calls, our approach prioritizes each
              patient&#39;s unique needs, utilizing cutting-edge solutions to
              optimize diagnostics, treatment plans, and overall health
              management
            </p>
            <p className="mb-5">
              Care XPS Medicine strives to redefine home healthcare, combining
              expertise with state-of-the-art technology to create a seamless
              and unparalleled healthcare experience for our valued patients.
              So, book your appointment today and experience the difference!
            </p>
            <a
              className={` rounded-2 text-white ${styles.startedbtn}`}
              target="blank"
              href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=fee48727-4828-402b-8293-103f7f1a87d0#/online-booking"
            >
              Get Started Now
            </a>
          </div>
        </Container>
      </div>
      <div className="px-sm-5 px-2 ">

        <SectionFive />
      </div>
      <Footer />
    </div>
  );
};

export default HomeCare;
