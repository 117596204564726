import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "./sectionone.module.css";
import { Col, Row } from "react-bootstrap";
import { whatToExpectContent } from "../../helper/Helper";

const Causes = (props) => {
  let { data, points, ul, title } = props;

  const ListItem = ({ data }) => {
    return (
      <li className={styles.sectionTwoListItem}>
        <span >{data?.title}</span>
        <p >{data?.text}</p>
      </li>
    );
  };

  const ListItemSmall = ({ data, title }) => {
    return (
      <li className={styles.sectionTwoListItem}>
        <p className={styles.regularText}>{data}</p>
      </li>
    );
  };

  const ListPara = ({ data }) => {
    return <p className={`my-sm-1 ${styles.regularText}`}>{data}</p>;
  };

  return (
    <div className={styles.SectionTwo}>
      <div className={`px-sm-4 py-sm-3 mt-sm-4 ${styles.SectionTwoInnerCont}`}>
        {title && (
          <div className={styles.sectionTwoHeader}>
            <div className={styles.sectionTwoHeaderTitle}>{title}:</div>
          </div>
        )}
        <div className={styles.sectionTwoUlWrapper}>
          {data?.map((item, index) => {
            return (
              <div key={index}>
                {item?.title ? (
                  <ul>
                    <ListItem data={item} />
                  </ul>
                ) : item?.text ? (
                  <ListPara data={item?.text} key={index} />
                ) : item?.points ? (
                  <div>
                    <div style={{ paddingLeft: item?.mh ? 20 : 0 }}>
                      <div className={styles.sectionTwoHeaderTitle2}>
                        {item?.heading}
                        {item?.note && <p className={styles.regularText}>{item?.note}</p>}
                      </div>
                      {item?.points?.map((item2, index2) => {
                        return (
                          <ul key={index2}>
                            <ListItemSmall data={item2} />
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Causes;
