import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../css/Login/login.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import GoogleButton from "../../components/Buttons/GoogleButton";
import { SignIn } from "../../helper/Helper";
import TextInput from "../../components/TextInputs/TextInput";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleForm = (e) => {
    const { name, value } = e.target;
    setLoginForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSignIn = () => {
    const { email, password } = loginForm;
    if (email !== "" && password !== "") {
      try {
        setLoading(true);
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/");
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            let errMsg = error?.message;
            alert(errMsg);
          });
      } catch (error) {
        setLoading(false);
        let errMsg = error?.message;
        alert(errMsg);
      }
    } else {
      alert("fill all details");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row className={styles.logoWrapper}>
          <Col xs={12}>
            <div className={styles.logoImg}></div>
          </Col>
        </Row>
        <Stack gap={3} className={styles.headingStack}>
          <div className={styles.headingText}>Login to Care XPS MEDICINE</div>
          <div className={styles.sloganText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            <br className="brDesktop" /> Turpis morbi pulvinar venenatis non.
          </div>
        </Stack>
        {/* Google Sign Up button component */}
        <GoogleButton />
        <div className={styles.seperator_lines}>
          <div className={styles.line} />
          <p>or</p>
          <div className={styles.line} />
        </div>
        {/* Form TextFieelds Starts here */}
        <div className={styles.formStack}>
          {SignIn?.map((item, index) => {
            return (
              <TextInput
                key={index}
                type={item?.type}
                placeholder={item?.placeholder}
                label={item?.label}
                name={item?.name}
                onChange={handleForm}
              />
            );
          })}
        </div>
        <div className={styles.signupButtonWrapper}>
          <div className={styles.checkbox_wrapper}>
            <input type="checkbox" />
            <p>Remember Me</p>
          </div>
          <button className={styles.SignUpButton} onClick={onSignIn}>
            {loading ? "Signing In..." : "Sign In"}
          </button>

          <div className={styles.directionalText}>
            Don’t have an account? <Link to="/signup">Sign up now</Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
