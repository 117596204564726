import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/DetailPage/main.module.css";
import { Col, Row } from "react-bootstrap";
import { whatToExpectContent } from "../../../helper/Helper";

const SectionTwo = () => {
  const ListItem = ({ data }) => {
    return (
      <li className={styles.sectionTwoListItem}>
        <span>{data?.title}</span>
        <p>{data?.slug}</p>
      </li>
    );
  };

  return (
    <div className={`${styles.SectionTwo}`}>
      <div className={`px-sm-4 py-sm-4 mb-sm-4 mt-sm-4 ${styles.SectionTwoInnerCont}`}>
        <div className={styles.sectionTwoHeader}>
          <div className={styles.sectionTwoHeaderTitle}>What to expect</div>
          <div className={styles.sectionTwoHeaderSlug}>
            Book your appointment today with a Canadian-licensed physician.
          </div>
        </div>
        <div className={styles.sectionTwoUlWrapper}>
          <ul>
            {whatToExpectContent?.map((item, index) => {
              return <ListItem data={item} key={index} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
