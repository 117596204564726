import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from "react-router";

export const SignUp = [
  {
    name: "email",
    type: "text",
    label: "Email",
    placeholder: "Email Address",
  },
  {
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Enter a username",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Password (min. 8 characters)",
  },
];
export const SignIn = [
  {
    name: "email",
    type: "text",
    label: "Email",
    placeholder: "Email Address",
  },

  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Password (min. 8 characters)",
  },
];
export const navbar = [
  // { to: "gh", title: "General health" },
  // { to: "sh", title: "Sexual health" },
  // { to: "shn", title: "Skin,Hair,Nails" },
  // { to: "pr", title: "Prescription Renewals" },
  // { to: "sc", title: "Specialized Care" },
  { to: "/about", title: "About CareXPS" },
  { to: "/homehealthcare", title: "Home Health Care" },
  { to: "/walkin", title: "Walk-in clinic and Urgent Care" },
  { to: "/contact_us", title: "Contact Us" },
];

export const treatmentMenu = [
  { to: "ac", title: "All Conditions" },
  { to: "gh", title: "General Health" },
  { to: "sh", title: "Sexual Health" },
  { to: "shn", title: "Skin,Hair,Nails" },
  { to: "pr", title: "Prescriptions" },
  { to: "sc", title: "Specialized Care" },
];

export const sociallogo = [
  `${require("../assets/facebook.png")}`,
  `${require("../assets/Twitter.png")}`,
  `${require("../assets/Instagram.png")}`,
  `${require("../assets/youtube.png")}`,
];

export const footer_menu = ["Home", "How it works", "Help / FAQ", "Privacy"];
export const featured_items = [
  "UTI",
  "Pink Eye",
  "Erectile Dysfunction (ED)",
  "High blood pressure",
  "Low testosterone",
  "Birth Control",
  "Weight Loss",
  "Asthma/COPD",
  "COVID-19",
  "Diabetes",
];
export const discriptions_list = [
  {
    heading: "Book your appointment",
    paragraph: "Start a discreet personalized visit online or in person",
    icon: require("../assets/infoIcons/1.png"),
  },
  {
    heading: "Personalized care plan",
    paragraph:
      "Your doctor will consult, diagnose, and prescribe a treatment catered to you.",
    icon: require("../assets/infoIcons/2.png"),
  },
  {
    heading: "Canadian-licensed physicians",
    paragraph: "We work with qualified doctors.",
    icon: require("../assets/infoIcons/3.png"),
  },
  {
    heading: "Your secure, encrypted health data",
    paragraph:
      "All of your health information is secure and protected by encryption technolog  and the law.",
    icon: require("../assets/infoIcons/4.png"),
  },
];
export const treatment_list = [
  {
    category: "General Health",
    to: "gh",
    items: [
      {
        name: "Sinus infection",
        icon: require("../assets/treatmentIcons/1.png"),
      },
      { name: "Pink eye", icon: require("../assets/treatmentIcons/2.png") },
      { name: "Cold sores", icon: require("../assets/treatmentIcons/3.png") },
      {
        name: "Acid reflux (GERD)",
        icon: require("../assets/treatmentIcons/4.png"),
      },
      {
        name: "Gout flares",
        icon: require("../assets/treatmentIcons/5.png"),
        slogan: "Previous diagnosis required",
      },
      {
        name: "Seasonal allergies",
        icon: require("../assets/treatmentIcons/6.png"),
      },
      { name: "Quit smoking", icon: require("../assets/treatmentIcons/7.png") },
      {
        name: "Motion sickness",
        icon: require("../assets/treatmentIcons/8.png"),
      },
      {
        name: "COVID-19",
        icon: require("../assets/treatmentIcons/9.png"),
        slogan: "POSITIVE TEST REQUIRED",
      },
      {
        name: "Sports physical",
        icon: require("../assets/treatmentIcons/10.png"),
      },
      { name: "Sick notes", icon: require("../assets/treatmentIcons/11.png") },
      {
        name: "Miscellaneous health",
        icon: require("../assets/treatmentIcons/12.png"),
      },
      {
        name: "Employee health",
        icon: require("../assets/treatmentIcons/13.png"),
      },
      {
        name: "Travel clinic",
        icon: require("../assets/treatmentIcons/14.png"),
      },
      {
        name: "Vaccine clinic",
        icon: require("../assets/treatmentIcons/15.png"),
      },
    ],
  },
  {
    category: "Sexual Health",
    to: "sh",
    items: [
      {
        name: "Erectile dysfunction (ED)",
        icon: require("../assets/treatmentIcons/16.png"),
      },
      { name: "UTI", icon: require("../assets/treatmentIcons/17.png") },
      {
        name: "Genital herpes",
        icon: require("../assets/treatmentIcons/18.png"),
        slogan: "Previous diagnosis required",
      },
      {
        name: "Birth control",
        icon: require("../assets/treatmentIcons/19.png"),
      },
      {
        name: "Vaginal yeast infection",
        icon: require("../assets/treatmentIcons/20.png"),
      },
      {
        name: "Low testosterone",
        icon: require("../assets/treatmentIcons/21.png"),
      },
    ],
  },
  {
    category: "Skin, Hair, Nails",
    to: "shn",
    items: [
      {
        name: "Eyelash growth",
        icon: require("../assets/treatmentIcons/22.png"),
      },
      {
        name: "Anti-aging skin care",
        icon: require("../assets/treatmentIcons/23.png"),
      },
      { name: "Acne", icon: require("../assets/treatmentIcons/24.png") },
      {
        name: "Male hair loss",
        icon: require("../assets/treatmentIcons/25.png"),
      },
      { name: "Dandruff", icon: require("../assets/treatmentIcons/26.png") },
      {
        name: "Eczema",
        icon: require("../assets/treatmentIcons/27.png"),
        slogan: "Previous diagnosis required",
      },
      {
        name: "Rosacea",
        icon: require("../assets/treatmentIcons/28.png"),
        slogan: "Previous diagnosis required",
      },
      {
        name: "Athlete's foot",
        icon: require("../assets/treatmentIcons/29.png"),
      },
      {
        name: "Nail infection",
        icon: require("../assets/treatmentIcons/30.png"),
      },
      { name: "Shingles", icon: require("../assets/treatmentIcons/31.png") },
      { name: "Rash", icon: require("../assets/treatmentIcons/32.png") },
      {
        name: "Hidradenitis Suppurativa",
        icon: require("../assets/treatmentIcons/33.png"),
      },
    ],
  },
  {
    category: "Prescription Renewals",
    to: "pr",
    items: [
      {
        name: "High blood pressure",
        icon: require("../assets/treatmentIcons/34.png"),
      },
      { name: "Asthma/COPD", icon: require("../assets/treatmentIcons/35.png") },
      {
        name: "Hypothyroidism",
        icon: require("../assets/treatmentIcons/36.png"),
      },
      { name: "Migraine", icon: require("../assets/treatmentIcons/37.png") },
      {
        name: "High cholesterol",
        icon: require("../assets/treatmentIcons/38.png"),
      },
      {
        name: "Diabetes",
        icon: require("../assets/treatmentIcons/39.png"),
      },
    ],
  },
  {
    category: "Specialized Care",
    to: "sc",
    items: [
      { name: "Weight Loss", icon: require("../assets/treatmentIcons/40.png") },
      {
        name: "Medical aesthetics",
        icon: require("../assets/treatmentIcons/41.png"),
      },
      {
        name: "Mental health",
        icon: require("../assets/treatmentIcons/42.png"),
      },
    ],
  },
];

export const sectionThreeContent = [
  {
    title: "Who we are?",
    icon: require("../assets/section3Icons/1.png"),
    slug: "An organization founded by caring physicians.",
  },
  {
    title: "Our Ethos.",
    icon: require("../assets/section3Icons/2.png"),
    slug: "Stellar care is at the core of what we do.",
  },
  {
    title: "We want to hear from you.",
    icon: require("../assets/section3Icons/3.png"),
    slug: "We have an open communication policy to hear from you on how we can improve the care we deliver our patients, so drop us a line.",
  },
];
export const sectionFourQuestions = [
  {
    que: "How does it work?",
    ans: "You can easily book your appointment online using our booking link and your OHIP card.",
  },
  {
    que: "How much does it cost?",
    ans: "We support OHIP, though it's important to note that specific medical services may not be covered by OHIP. Our pricing for these uncovered medical services is transparent and readily available for your reference.",
  },

  {
    que: "Who are CareXPS Health Providers?",
    ans: "CareXPS Health’s providers are made up of board-certified clinicians with vast experience in primary care and chronic condition care. The clinical team includes physicians, nurse practitioners, physician assistants, care coordinators, and traveling clinical assistants who are all full-time employees of CareXPS Health and work together to provide patients comprehensive, in-home medical care.",
  },
  {
    que: "Do you accept OHIP and Insurance?    ",
    ans: "Yes. We accept OHIP for now. We’re always adding more plans and our team can verify insurance coverage for any resident interested in becoming a patient.",
  },
];
// {
//   que: "How do I choose an online clinic?",
//   ans: "",
// },
// {
//   que: "Can I use my health insurance to pay for the visit?",
//   and: "",
// },
// {
//   que: "Can I use health insurance to pay for any medications my clinician might prescribe?",
//   and: "",
// },

export const whatToExpectContent = [
  {
    title: "Consultation",
    slug: "So basically you will have a conversation with a doctor about your health and symptoms, and they will help you figure out how to get better or stay healthy.",
  },
  {
    title: "Diagnosis",
    slug: "A doctor figures out what's making you sick. They use your symptoms, test results, and other info to name the problem and decide how to treat it.",
  },
  {
    title: "Treatment",
    slug: "A doctor will help you get better by prescribing a medication if needed, or recommending an alternative treatment. A prescription will be sent to your pharmacy of choice.",
  },
];

export const howItWorksContent = [
  {
    title: "Who we are?",
    description: "An organization founded by caring physicians.",
    img: require("../assets/get-started-icon._CB1654288597_.png"),
  },
  {
    title: "Our Ethos.",
    description: "Stellar care is at the core of what we do.",
    img: require("../assets/clinician-icon._CB1654288597_.png"),
  },
  {
    title: "We want to hear from you.",
    description:
      "We have an open communication policy to hear from you on how we can improve the care we deliver our patients, so drop us a line.",
    img: require("../assets/treatment-icon._CB1654288597_.png"),
  },
];

export const SignInWithGoogle = (navigate) => {
  try {
    const auth = getAuth();

    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate("/");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorMessage);
      });
  } catch (e) {
    console.log(e.message);
  }
};

export function camelize(str) {
try{
  if (!str) return str;
  let words = str?.split(" ");
  let newWords = "";
  words?.map((item, index) => {
    let _t = item.replace(/^./, function (str) {
      return str.toUpperCase();
    });
    newWords += _t;
    newWords += " ";
  });
  return newWords;
}catch(e){
  console.log(e)
  console.log("string here",str)
}
}

export const detailPageContent = [
  {
    title: "Sinus infection",
    symptoms: [
      "nasal congestion",
      "facial pain or pressure",
      "thick nasal discharge",
      "reduced sense of smell",
      "coughing",
    ],
    causes: [
      {
        title: null,
        text: "The three most common causes of sinus infections are viral infections, bacterial infections, and allergies. Viral infections often lead to inflammation of the sinus lining, bacterial infections can result from unresolved viral infections or other factors, and allergies can trigger inflammation and blockages in the sinus passages.",
      },
    ],
    treatments: [
      {
        que: "1. Rest and Hydration",
        text: "Getting plenty of rest and staying hydrated can help your body fight off the infection and ease symptoms.",
      },
      {
        que: "2. Nasal Decongestants",
        text: "Over-the-counter or prescription nasal decongestants can help reduce congestion and improve breathing.",
      },
      {
        que: "3. Antibiotics (if bacterial) ",
        text: "If the infection is bacterial in nature, a course of antibiotics prescribed by a healthcare professional may be necessary to clear the infection.",
      },
    ],
    // slugText:
    //   "It's important to consult a healthcare provider for proper diagnosis and treatment recommendations based on the specific nature and severity of your sinus infection.",
    onlyDesc: null,
  },
  {
    title: "Pink eye",
    symptoms: [
      "redness of the eye",
      "itching or irritation",
      "watery or thick discharge",
      "crusty eyelids upon waking",
    ],
    causes: [
      {
        title: null,
        text: `The three most common causes of pink eye are viral infections, 
        bacterial infections, and allergies. Viral conjunctivitis is often accompanied by cold-like symptoms, 
        bacterial conjunctivitis can result from various bacteria entering the eye, 
        and allergic conjunctivitis is triggered by allergens such as pollen or pet dander, leading to inflammation of the conjunctiva.`,
      },
    ],
    treatments: [
      {
        que: "1. Antibiotic Eye Drops or Ointments",
        text: "For bacterial conjunctivitis, healthcare providers often prescribe antibiotic eye drops or ointments to help clear the infection.",
      },
      {
        que: "2. Antiviral Medications",
        text: "In the case of viral conjunctivitis, antiviral medications may be recommended to alleviate symptoms and speed up recovery.",
      },
      {
        que: "3. Antihistamine Eye Drops ",
        text: "For allergic conjunctivitis, antihistamine eye drops can help reduce inflammation and relieve itching and discomfort.",
      },
    ],
    // slugText:
    //   "It is crucial to consult a healthcare professional for proper diagnosis and treatment recommendations based on the specific type and severity of pink eye you have. Self-medication is not advisable, as using incorrect treatments can worsen the condition or lead to complications",
    onlyDesc: null,
  },
  {
    title: "Cold sores",
    symptoms: [
      "painful",
      "fluid-filled blisters or sores on or around the lips",
      "tingling or burning sensations before the blisters appear",
      "crusting as the sores heal",
    ],
    causes: [
      {
        title: null,
        text: `The three most common causes of cold sores are the herpes simplex virus type 1 (HSV-1), stress, and weakened immune system. HSV-1 is highly contagious and can be transmitted through direct contact with a person who has an active cold sore. Stress can trigger outbreaks in individuals who carry the virus, and a weakened immune system may lead to increased susceptibility to cold sore outbreaks.`,
      },
    ],
    treatments: [
      {
        que: "1.	Antiviral Medications",
        text: "Prescription antiviral medications, such as acyclovir, valacyclovir, and famciclovir, can help reduce the severity and duration of cold sore outbreaks. These medications are most effective when taken early during the prodromal stage (tingling or burning sensation before blisters appear) or at the first sign of an outbreak.",
      },
      {
        que: "2.	Topical Antiviral Creams",
        text: "Over-the-counter or prescription topical antiviral creams containing ingredients like docosanol or acyclovir can be applied directly to the cold sores to help alleviate symptoms and promote faster healing.",
      },
      {
        que: "3.	Pain Relief Medications ",
        text: "Over-the-counter pain relievers, such as acetaminophen or ibuprofen, can help manage the discomfort associated with cold sores, including pain, inflammation, and fever.",
      },
    ],
    // slugText:
    //   "Remember to consult a healthcare professional for proper diagnosis and treatment recommendations tailored to your specific condition. Additionally, taking measures to prevent cold sore triggers, such as reducing stress and maintaining a healthy immune system, can help minimize the frequency of outbreaks.",
    onlyDesc: null,
  },
  {
    title: "Acid reflux (GERD)",
    symptoms: [
      "heartburn",
      "a burning sensation in the chest or throat",
      "regurgitation of stomach acid or food",
      "difficulty swallowing",
    ],
    causes: [
      {
        title: "Hiatal Hernia: ",
        text: "A hiatal hernia occurs when a portion of the stomach protrudes through the diaphragm, leading to an increased risk of acid reflux.",
      },
      {
        title: "Weak Lower Esophageal Sphincter (LES): ",
        text: "The LES is a muscle at the bottom of the esophagus that prevents stomach acid from flowing back into the esophagus. When the LES is weak or relaxed, it allows acid to reflux.",
      },
      {
        title: "Diet and Lifestyle Factors:",
        text: "Certain foods, such as spicy, fatty, and acidic items, as well as caffeine, alcohol, and smoking, can trigger acid reflux. Additionally, obesity and lying down after eating can exacerbate the condition.",
      },
    ],
    treatments: [
      {
        que: "1. Antiviral Medications",
        text: "Prescription antiviral medications, such as acyclovir, valacyclovir, and famciclovir, can help reduce the severity and duration of cold sore outbreaks. These medications are most effective when taken early during the prodromal stage (tingling or burning sensation before blisters appear) or at the first sign of an outbreak.",
      },
      {
        que: "2. Topical Antiviral Creams",
        text: "Over-the-counter or prescription topical antiviral creams containing ingredients like docosanol or acyclovir can be applied directly to the cold sores to help alleviate symptoms and promote faster healing.",
      },
      {
        que: "3. Pain Relief Medications",
        text: "Over-the-counter pain relievers, such as acetaminophen or ibuprofen, can help manage the discomfort associated with cold sores, including pain, inflammation, and fever.",
      },
    ],
    // slugText:
    //   "Remember to consult a healthcare professional for proper diagnosis and treatment recommendations tailored to your specific condition. Additionally, taking measures to prevent cold sore triggers, such as reducing stress and maintaining a healthy immune system, can help minimize the frequency of outbreaks.",
    onlyDesc: null,
  },
  {
    title: "Gout flares",
    symptoms: [
      "intense joint pain",
      "usually affecting the big toe",
      "but can also impact other joints like the ankles",
      "knees",
      "fingers",
      "The affected area becomes swollen, red, and tender to the touch during a gout attack",
    ],
    causes: [
      {
        title: "High Uric Acid Levels: ",
        text: "Gout is caused by an accumulation of uric acid in the blood, leading to the formation of urate crystals in the joints. When these crystals trigger inflammation, it results in a gout flare.",
      },
      {
        title: "Dietary Factors: ",
        text: "Consuming purine-rich foods like red meat, organ meats, seafood, and sugary beverages can increase uric acid levels and raise the risk of gout flares.",
      },
      {
        title: "Genetics and Lifestyle: ",
        text: "Some people have a genetic predisposition to higher uric acid levels, increasing their susceptibility to gout. Lifestyle factors such as obesity, excessive alcohol consumption, and certain medications can also contribute to gout development.",
      },
      {
        title: null,
        text: "Prompt diagnosis and appropriate management are crucial to mitigate the impact of gout flares on joint health and overall well-being. If you suspect you have gout or experience symptoms, it's essential to consult a healthcare professional for accurate assessment and tailored treatment options.",
      },
    ],
    treatments: [
      {
        que: "1.	Nonsteroidal Anti-Inflammatory Drugs (NSAIDs) ",
        text: "NSAIDs, such as ibuprofen or naproxen, are commonly used to reduce pain and inflammation during gout flares.",
      },
      {
        que: "2.	Colchicine ",
        text: "Colchicine is an anti-inflammatory medication specifically used to treat acute gout attacks. It helps to alleviate pain and reduce inflammation.",
      },
      {
        que: "3.	Corticosteroids ",
        text: "In cases where NSAIDs or colchicine are not suitable or effective, corticosteroids may be prescribed to reduce inflammation and provide relief during gout flares.",
      },
    ],
    // slugText:
    //   "It is essential to seek medical advice and get an accurate diagnosis before starting any treatment, as gout management may involve long-term strategies to prevent future flares and manage the underlying condition",
    onlyDesc: null,
  },
  {
    title: "Seasonal allergies",
    symptoms: [
      "fever or allergic rhinitis",
      "sneezing",
      "nasal congestion",
      "runny nose",
      "itchy or watery eyes",
      "itching of the nose",
      "throat",
    ],
    causes: [
      {
        title: "Pollen: ",
        text: "Pollen from trees, grasses, and weeds is a common allergen during specific times of the year, triggering allergic reactions in susceptible individuals.",
      },
      {
        title: "Mold Spores:  ",
        text: "Mold spores are present in the air, especially in damp and humid environments, and can lead to allergies in sensitive individuals.",
      },
      {
        title: "Dust Mites: ",
        text: "Dust mites are tiny insects that thrive in household dust, bedding, and upholstery. Their feces and body parts can trigger allergic reactions when inhaled.",
      },
      {
        title: null,
        text: "Understanding the triggers and managing seasonal allergies is essential for finding relief. Over-the-counter antihistamines, nasal corticosteroids, and decongestants are commonly used to alleviate symptoms. If symptoms persist or worsen, consulting a healthcare professional can help identify specific allergens and provide more targeted treatment options.",
      },
    ],
    treatments: [
      {
        que: "1.	Antihistamines",
        text: "Antihistamines are widely used to block the action of histamine, a chemical released during an allergic reaction. They help relieve symptoms such as sneezing, runny nose, and itchy eyes.",
      },
      {
        que: "2.	Nasal Corticosteroids",
        text: "Nasal corticosteroid sprays are effective in reducing inflammation in the nasal passages, alleviating symptoms like nasal congestion, itching, and sneezing.",
      },
      {
        que: "3.	Decongestants ",
        text: "Decongestants, available as nasal sprays or oral medications, can temporarily alleviate nasal congestion and sinus pressure caused by seasonal allergies.",
      },
    ],
    // slugText:
    //   "As with any medical treatment, it is advisable to consult a healthcare professional for proper diagnosis and personalized treatment recommendations based on your specific allergy symptoms and medical history.",
    onlyDesc: null,
  },
  {
    title: "Quit smoking",
    symptoms: [],
    causes: [],
    treatments: [
      {
        que: "1. Behavioral Counseling ",
        text: "Behavioral counseling helps individuals address the psychological and behavioral aspects of quitting. ",
      },
      {
        que: "2. Nicotine Replacement Therapy (NRT) ",
        text: "NRT provides nicotine in forms like patches, gum, lozenges, or inhalers to help reduce withdrawal symptoms. ",
      },
      {
        que: "3. Prescription Medications ",
        text: "Prescription medications like bupropion and varenicline can also aid in reducing cravings and withdrawal symptoms, making the quitting process more manageable. Combining these treatments has been shown to be the most effective approach in successfully quitting smoking.",
      },
    ],
    slugText:
      "Health Risk Factors of Smoking Tobacco: The most common health risk factors associated with smoking tobacco include an increased risk of developing lung cancer, chronic obstructive pulmonary disease (COPD), heart disease, stroke, and various respiratory problems. Smoking can also negatively impact overall lung function, worsen asthma symptoms, and increase the likelihood of other cancers.\n\n\nTreatments: The three most common treatments for smoking cessation are behavioral counseling, nicotine replacement therapy (NRT), and prescription medications. Behavioral counseling helps individuals address the psychological and behavioral aspects of quitting. NRT provides nicotine in forms like patches, gum, lozenges, or inhalers to help reduce withdrawal symptoms. Prescription medications like bupropion and varenicline can also aid in reducing cravings and withdrawal symptoms, making the quitting process more manageable. Combining these treatments has been shown to be the most effective approach in successfully quitting smoking.",
    onlyDesc: null,
  },
  {
    title: "Motion sickness",
    symptoms: [
      "nausea.",
      "vomiting",
      "vomiting",
      "sweating",
      "a general feeling of discomfort or unease.",
    ],
    causes: [
      {
        title: null,
        text: "The three most common causes of motion sickness are conflicting sensory signals, repetitive motion, and individual susceptibility. Conflicting sensory signals occur when the brain receives different information from the eyes, inner ears, and other sensory systems, such as when reading in a moving vehicle. Repetitive motion, like during travel by boat, car, or airplane, can also trigger motion sickness. Additionally, some individuals are more prone to motion sickness due to differences in their vestibular system's sensitivity and adaptability.",
      },
      {
        title: null,
        text: "Understanding these common symptoms and causes can help individuals identify motion sickness early and take appropriate measures to alleviate or prevent its onset.",
      },
    ],
    treatments: [
      {
        que: "1.	Antihistamines ",
        text: "Over-the-counter or prescription antihistamines, such as dimenhydrinate (Dramamine) or meclizine (Bonine), are commonly used to prevent and treat motion sickness. They work by blocking histamine receptors in the brain, reducing the symptoms of nausea and dizziness.",
      },
      {
        que: "2.	Scopolamine Patches ",
        text: "Transdermal scopolamine patches are prescription medications that can be applied behind the ear to prevent motion sickness. The patch slowly releases scopolamine, which helps control motion sickness symptoms for an extended period.",
      },
      {
        que: "3.	Ginger ",
        text: "Although not a conventional medical treatment, ginger has been found to be effective in reducing motion sickness symptoms in some individuals. Ginger can be consumed in various forms, such as ginger candies, ginger tea, or ginger supplements.",
      },
    ],
    // slugText:
    //   "It's important to consult with a healthcare professional before using any medication or remedy for motion sickness, especially if you have any underlying health conditions or are taking other medications.",
    onlyDesc: null,
  },
  {
    title: "COVID-19",
    symptoms: [
      "Fever.",
      "Cough.",
      "Shortness of breath or difficulty breathing.",
      "Fatigue.",
      "Muscle or body aches.",
      "Loss of taste or smell.",
      "Sore throat.",
      "Congestion or runny nose.",
      "Headache.",
      "Nausea or vomiting.",
      "Diarrhea.",
      "It's important to note that symptoms can vary widely, and some individuals may experience mild symptoms or even be asymptomatic. If you suspect you have COVID-19 or are experiencing any of these symptoms, it's recommended to seek medical advice and follow public health guidelines",
    ],
    causes: [],
    treatments: [
      {
        que: "1.	Supportive Care ",
        text: "This includes managing symptoms such as fever, cough, and body aches with over-the-counter medications, staying hydrated, and getting plenty of rest.",
      },
      {
        que: "2.	Antiviral Medications ",
        text: "Some antiviral medications, like remdesivir and paxlovid have been used to treat COVID-19 in certain cases, aiming to reduce the severity and duration of symptoms.",
      },
      {
        que: "3.	Monoclonal Antibody Therapy ",
        text: "Monoclonal antibodies, such as casirivimab and imdevimab, have been authorized for emergency use to treat mild to moderate COVID-19 in high-risk individuals, helping to prevent disease progression.",
      },
    ],
    onlyDesc: null,
  },
  {
    title: "Sports physical",
    symptoms: [],
    causes: [],
    treatments: [],
    slugText:
      "A sports physical is crucial as it assesses an individual's overall health and fitness level, identifying any underlying medical conditions that could affect their participation in sports. It helps detect potential issues early, minimizing the risk of serious complications during physical activity. Not having a sports physical increases the chance of undiagnosed health conditions leading to injury, poor performance, or even life-threatening situations during sports. It also ensures that athletes receive proper guidance on injury prevention, training, and optimal performance, enhancing their overall well-being and athletic experience.",
    onlyDesc: null,
  },
  {
    title: "Sick notes",
    symptoms: [],
    causes: [],
    treatments: [],
    slugText:
      "A sick note, also known as a medical certificate or doctor's note, is a formal document issued by a healthcare provider to confirm an individual's illness and need for time off work. It outlines the individual's medical condition, the recommended duration of leave, and any restrictions or accommodations necessary. This note serves as evidence for the employer, allowing the employee to take medically warranted time off while maintaining transparency and adhering to workplace policies.",
    onlyDesc: null,
  },
  {
    title: "Miscellaneous health",
    symptoms: [],
    causes: [],
    treatments: [],
    slugText:
      "A medical office addresses a wide range of miscellaneous health issues, including minor injuries, skin conditions, allergies, digestive problems, and routine check-ups. These offices provide diagnosis, treatment, and preventive care for common ailments such as colds, flu, rashes, and minor infections. They also offer vaccinations, screenings, and advice on maintaining general well-being. Medical offices play a crucial role in early detection of health concerns, guiding patients towards appropriate care and promoting overall health.",
    onlyDesc: null,
  },
  {
    title: "Employee health",
    symptoms: [],
    causes: [],
    treatments: [],
    slugText:
      "A simple employee health department is a dedicated section within an organization that focuses on the health and well-being of its employees. Its purpose is to promote a safe and healthy work environment while ensuring employees are fit for their job roles. The department's functions include conducting medical screenings, managing health-related records, offering vaccinations, and providing guidance on workplace safety measures. Common medical screenings performed by such departments include pre-employment health assessments, regular health check-ups, vision and hearing tests, blood pressure checks, and screenings for infectious diseases. These screenings help identify potential health issues early, contributing to both employee well-being and workplace productivity.",
    onlyDesc: null,
  },
  {
    title: "Travel clinic",
    symptoms: [],
    causes: [],
    treatments: [],
    slugText:
      "A simple travel health department is a specialized branch of healthcare that focuses on providing medical advice, vaccinations, and preventive measures for individuals planning to travel internationally. Its purpose is to ensure travelers are well-informed about potential health risks in their destination and to protect them from various diseases and infections. The department's functions include offering travel health consultations, administering necessary immunizations, prescribing medications for travel-related health concerns, and providing guidance on health and safety precautions during the trip. Common medical immunizations performed by such departments include vaccinations for diseases like yellow fever, typhoid, hepatitis A and B, meningitis, and tetanus. These immunizations help travelers stay healthy and safe while exploring new regions.",
    onlyDesc: null,
  },
  {
    title: "Vaccine clinic",
    symptoms: [],
    causes: [],
    treatments: [],
    slugText:
      "A simple vaccine clinic is a specialized facility that focuses on providing vaccinations to individuals of all ages. Its purpose is to protect people from preventable diseases by administering vaccines that stimulate the immune system to develop immunity. The clinic's primary function is to offer a safe and convenient environment for receiving immunizations. Common medical immunizations performed by vaccine clinics include childhood vaccinations like measles, mumps, rubella (MMR), polio, and diphtheria-tetanus-pertussis (DTaP). They also provide vaccinations for adolescents, adults, and travelers, including influenza, HPV, meningococcal, and hepatitis vaccines. The clinic plays a crucial role in promoting public health by preventing the spread of contagious diseases through widespread vaccination.",
    onlyDesc: null,
  },
  {
    title: "Erectile dysfunction (ED)",
    symptoms: [
      "difficulty achieving or maintaining an erection.",
      "reduced sexual desire.",
      "trouble with achieving satisfactory sexual performance.",
    ],
    causes: [
      {
        title: null,
        text: "The three most common causes of erectile dysfunction are cardiovascular issues, diabetes, and psychological factors. Cardiovascular problems can affect blood flow to the penis, hindering the ability to achieve and maintain an erection. Diabetes can damage nerves and blood vessels, contributing to ED. Psychological factors like stress, anxiety, and depression can also play a significant role in causing or exacerbating erectile dysfunction.",
      },
      {
        title: null,
        text: "Recognizing these symptoms and causes is essential for seeking appropriate medical guidance and interventions to address erectile dysfunction effectively.",
      },
    ],
    treatments: [
      {
        que: "1.	Testosterone Replacement Therapy (TRT) ",
        text: "TRT involves administering testosterone through injections, gels, patches, or implants to restore hormone levels to a normal range.",
      },
      {
        que: "2.	Lifestyle Changes ",
        text: "Making healthy lifestyle changes like adopting a balanced diet, engaging in regular exercise, managing stress, and getting adequate sleep can help improve testosterone levels.",
      },
      {
        que: "3.	Medication ",
        text: "Certain medications, such as clomiphene citrate, can stimulate the body's own testosterone production. However, these medications are typically used when fertility preservation is a concern.",
      },
    ],
    onlyDesc: null,
  },
  {
    title: "UTI",
    symptoms: [
      "frequent urge to urinate.",
      "pain or burning sensation during urinatio.",
      "cloudy or bloody urine.",
      "discomfort or pressure in the lower abdomen or back.",
    ],
    causes: [
      {
        title: null,
        text: "The three most common causes of UTIs are bacterial infection, sexual activity, and improper hygiene. Bacterial infection occurs when bacteria enter the urinary tract, commonly the bladder or urethra. Sexual activity can introduce bacteria into the urinary tract, especially in females. Poor hygiene practices, such as wiping from back to front after using the toilet, can also contribute to UTIs.",
      },
      {
        title: null,
        text: "Recognizing these symptoms and causes is important for seeking timely medical attention and adopting preventive measures to minimize the risk of UTIs.",
      },
    ],
    treatments: [
      {
        que: "1.	Antibiotics ",
        text: "Antibiotics are the primary treatment for UTIs. They help eliminate the bacteria causing the infection and relieve symptoms. Commonly prescribed antibiotics include trimethoprim-sulfamethoxazole, nitrofurantoin, and ciprofloxacin.",
      },
      {
        que: "2.	Pain Relievers ",
        text: "Over-the-counter pain relievers like ibuprofen or acetaminophen can help alleviate discomfort and pain associated with UTI symptoms, such as a burning sensation during urination or lower abdominal pain.",
      },
      {
        que: "3.	Increased Fluid Intake ",
        text: "Drinking plenty of water and staying hydrated can help flush out bacteria and promote healing. It's a supportive measure that complements antibiotic treatment.",
      },
    ],
    onlyDesc: null,
  },
  {
    title: "Genital herpes",
    symptoms: [
      "painful sores or blisters on the genital or anal area.",
      "itching.",
      "tingling",
      "flu-like symptoms such as fever and body aches.",
    ],
    causes: [],
    treatments: [],
    // slugText:
    //   "The three most common causes of genital herpes are the herpes simplex virus type 1 (HSV-1), herpes simplex virus type 2 (HSV-2), and sexual transmission. HSV-1 and HSV-2 are responsible for causing genital herpes through direct skin-to-skin contact, including sexual activity. HSV-1 is commonly associated with oral herpes (cold sores), but it can also lead to genital herpes through oral-genital contact.",
    onlyDesc: null,
  },
  {
    title: "Birth control",
    introText:
      "People seek birth control for a multitude of reasons, influenced by their circumstances, cultural norms, and personal choices. Despite this diversity, there are several evidence-based rationales commonly cited for utilizing birth control methods. These reasons stem from considerations such as family planning, reproductive health, and contraceptive efficacy.",
    reasons: [
      {
        title: "1.	Family Planning:",
        text: "One of the most commonly cited reasons for using birth control is to plan the timing and number of children a person or couple wants to have. Birth control allows individuals and couples to engage in sexual activity without the immediate risk of pregnancy, thereby giving them greater control over their reproductive futures.",
      },
      {
        title: "2.	Economic Stability:",
        text: "Raising a child is a significant financial commitment. Many people opt for birth control methods to ensure they are economically stable before taking on the responsibility of having a child. This is often considered a responsible choice, both for the parents and the child's future well-being.",
      },
      {
        title: "3.	Health Reasons: ",
        text: "Pregnancy could be a health risk for some individuals, particularly those with certain medical conditions or risk factors. In these cases, birth control is used as a preventative measure. Some forms of birth control, like hormonal birth control pills, are used to manage conditions like polycystic ovary syndrome (PCOS), endometriosis, and excessively heavy or painful menstrual periods.",
      },
      {
        title: "4.	Educational and Career Goals: ",
        text: "Many people, especially younger adults, use birth control to focus on their education or career. An unplanned pregnancy can disrupt academic and professional trajectories, impacting earning potential and career advancement.",
      },
      {
        title: "5.	Sexual Autonomy and Freedom: ",
        text: "Birth control also allows individuals greater sexual freedom, enabling them to engage in sexual activity without the constant concern of unintended pregnancy. This has psychological benefits and contributes to a healthier sexual relationship between partners.",
      },
    ],
    causes: [],
    treatments: [
      "The common evidence-based birth control options are:",
      {
        que: "Combined Oral Contraceptives (COCs)",
        text: "COCs are highly effective in preventing pregnancy when taken as directed, with a failure rate of less than 1%. Additionally, they offer non-contraceptive benefits such as regulating menstrual cycles, reducing menstrual cramps, and improving acne.",
      },
      {
        que:"Intrauterine Devices (IUDs)",
        text:"IUDs are one of the most effective forms of reversible birth control, with a failure rate of less than 1%. They provide long-term contraception, lasting from 3 to 10 years, depending on the type, and are low maintenance once inserted."
      },
      {
        que:"Contraceptive Implants",
        text:"Contraceptive implants, such as Nexplanon, are highly effective, with a failure rate of less than 1%. They offer long-lasting contraception for up to three years and are discreet and convenient once inserted under the skin of the upper arm."
      }
    ],
    // afterReasonText1: `Note that these reasons are not mutually exclusive, and individuals may seek birth control for a combination of these factors. Additionally, these reasons can be influenced by various social, cultural, and personal considerations.
    //   When considering birth control options, it's essential to consult a healthcare provider for advice tailored to individual medical histories and needs.`,
    // beforeTreatmentText:
    //   "Their failure rate often measures the effectiveness of birth control methods—the percentage of people who experience an unintended pregnancy within the first year of typical use. Based on this, here are some of the top evidence-based methods of birth control, listed in no particular order:",
    onlyDesc: null,
  },
  {
    title: "Vaginal yeast infection",
    symptoms: [
      "itching.",
      "burning.",
      "irritation in the vaginal area.",
      "thick white discharge resembling cottage cheese.",
    ],
    causes: [
      {
        title: null,
        text: "The three most common causes of vaginal candidiasis are Candida overgrowth, hormonal changes, and weakened immune system. Candida, a type of yeast, can overgrow in the vagina due to factors like antibiotic use, hormonal fluctuations (such as during pregnancy or while taking birth control pills), and conditions that weaken the immune system like diabetes.",
      },
      {
        title: null,
        text: "Understanding these symptoms and causes helps individuals recognize and manage vaginal candidiasis, seeking appropriate medical guidance for accurate diagnosis and effective treatment.",
      },
    ],
    treatments: [
      {
        que: "1.	Antifungal Creams or Suppositories ",
        text: "Over-the-counter or prescription antifungal creams or suppositories containing medications like miconazole, clotrimazole, or terconazole are applied directly to the affected area to treat the infection.",
      },
      {
        que: "2.	Oral Antifungal Medications ",
        text: "Prescription oral antifungal medications like fluconazole can be taken to treat more severe or recurrent cases of vaginal candidiasis.",
      },
      {
        que: "3.	Home Remedies and Preventive Measures ",
        text: "Some individuals may find relief through home remedies such as probiotics or using plain yogurt. Practicing good genital hygiene, wearing breathable underwear, and avoiding irritants can also help prevent future yeast infections.",
      },
    ],
    // slugText:
    //   "Consulting a healthcare provider is recommended for proper diagnosis and treatment guidance, especially if symptoms are severe or recurring.",
    onlyDesc: null,
  },

  {
    title: "Low testosterone",
    symptoms: [
      "sex drive.",
      "fatigue.",
      "decreased muscle mass.",
      "increased body fat.",
      "mood changes like irritability or depression..",
    ],
    causes: [
      {
        title: null,
        text: "The three most common causes of low testosterone are aging, certain medical conditions, and lifestyle factors. As men age, testosterone levels naturally decline. Medical conditions such as hypogonadism, obesity, diabetes, and hormonal disorders can also lead to low testosterone. Lifestyle factors like poor diet, lack of exercise, excessive alcohol consumption, and chronic stress can contribute to decreased testosterone production.",
      },
      {
        title: null,
        text: "Understanding these symptoms and causes is crucial for early detection and appropriate management of low testosterone, which can impact overall well-being and quality of life.",
      },
    ],
    treatments: [
      {
        que: "1.	Testosterone Replacement Therapy (TRT) ",
        text: "TRT involves administering testosterone through injections, gels, patches, or implants to restore hormone levels to a normal range.",
      },
      {
        que: "2.	Lifestyle Changes ",
        text: "Making healthy lifestyle changes like adopting a balanced diet, engaging in regular exercise, managing stress, and getting adequate sleep can help improve testosterone levels.",
      },
      {
        que: "3.	Medications ",
        text: "Certain medications, such as clomiphene citrate, can stimulate the body's own testosterone production. However, these medications are typically used when fertility preservation is a concern.",
      },
    ],
    // slugText:
    //   "It's important to consult a healthcare professional before pursuing any treatment, as the appropriate approach depends on the individual's health status and specific needs.",
    onlyDesc: null,
  },

  {
    title: "Anti-aging skin care",
    symptoms: [],
    benefits: [
      "Reduced Wrinkles and Fine Lines: Anti-aging skincare can help minimize the appearance of wrinkles and fine lines, promoting a smoother complexion.",
      "Improved Skin Texture: Regular use of anti-aging products can enhance skin texture, making it appear more even and refined.",
      "Enhanced Skin Hydration: Anti-aging products often contain moisturizing ingredients that help improve skin's hydration levels, reducing dryness and flakiness.",
      "Even Skin Tone: Certain anti-aging products address issues like age spots and hyperpigmentation, leading to a more even and radiant skin tone.",
      "Stimulation of Collagen Production: Some anti-aging ingredients stimulate collagen production, promoting skin's elasticity and firmness.",
      "Protection from Environmental Factors: Antioxidants in anti-aging products can protect skin from damage caused by environmental pollutants and UV rays.",
      "Youthful Glow: Effective anti-aging skincare can result in a youthful, healthy glow that boosts overall self-confidence.",
    ],
    causes: [],
    treatments: [
      {
        que: "1.	Sun Protection",
        text: "Regular use of broad-spectrum sunscreen with at least SPF 30 helps protect the skin from harmful UV rays that accelerate skin aging, including wrinkles, fine lines, and age spots.",
      },
      {
        que: "2.	Moisturization",
        text: "Using a quality moisturizer helps keep the skin hydrated, improving its elasticity and reducing the appearance of wrinkles and dryness.",
      },
      {
        que: "3.	Retinoids",
        text: "Products containing retinoids, like retinol or prescription retinoids, are known to stimulate collagen production, improve skin texture, and reduce the appearance of fine lines and wrinkles.",
      },
    ],
    // slugText2:
    //   "Incorporating these strategies into your skincare routine can help maintain healthier and more youthful-looking skin over time.",
    // slugText:
    //   "Consistent use of well-formulated anti-aging products can offer these benefits, helping individuals maintain more youthful and vibrant skin as they age.",
    onlyDesc: null,
  },
  {
    title: "Eyelash growth",
    symptoms: [],
    benefits: [
      "Enhanced Appearance: Long eyelashes can enhance the overall appearance of your eyes, making them look bigger, brighter, and more expressive.",
      "Frame for the Eyes: Eyelashes serve as a natural frame for your eyes, drawing attention to their beauty and depth.",
      "Reduced Need for Makeup: With long lashes, you may feel less reliant on mascara or eyelash curlers to achieve an attractive look.",
      "Boosted Confidence: The improved aesthetic of long lashes can boost self-esteem and confidence.",
      "Eye Protection: Eyelashes help protect your eyes from dust, debris, and excessive sunlight.",
      "Soft Focus Effect: Longer lashes can create a soft focus effect that blurs imperfections around the eyes, making you look more refreshed.",
      "Youthful Appearance: Long lashes can contribute to a more youthful appearance by accentuating your eyes' vibrancy.",
      "Minimal Maintenance: Longer lashes require less daily makeup application or use of false lashes, saving you time and effort.",
      "Complementary to Makeup: Long eyelashes can enhance the impact of makeup, such as eyeshadow and eyeliner.",
      "While many people desire long eyelashes for cosmetic reasons, it's important to prioritize overall eye health and safety when considering any treatments or enhancements.",
    ],
    causes: [],
    treatments: [
      {
        que: "1.	Bimatoprost (Latisse) ",
        text: "This prescription medication is applied to the base of the upper eyelashes to stimulate their growth. It's known to increase both the length and thickness of eyelashes.",
      },
      {
        que: "2.	Lash Serums ",
        text: "Over-the-counter lash serums often contain peptides and other nourishing ingredients that can promote healthier and potentially longer eyelashes over time.",
      },
      {
        que: "3.	Eyelash Extensions",
        text: " While not a medical treatment, eyelash extensions involve applying individual synthetic lashes to natural lashes, creating a longer and fuller appearance. This is a temporary cosmetic solution.",
      },
    ],
    // slugText:
    //   "Incorporating these strategies into your skincare routine can help maintain healthier and more youthful-looking skin over time.",
    onlyDesc: null,
  },

  {
    title: "Acne",
    symptoms: [
      "Whiteheads and Blackheads: Small, raised bumps with white or black centers.",
      "Papules: Red, inflamed bumps that may be tender to touch.",
      "Pustules: Pimples with a white or yellow center containing pus.",
      "Cysts: Large, painful, pus-filled lumps beneath the skin's surface.",
      "Nodules: Hard, painful lumps beneath the skin without a visible center.",
    ],
    causes: [
      {
        title: "Excess Oil Production: ",
        text: "Overactive oil glands can lead to clogged pores.",
      },
      {
        title: "Bacteria:",
        text: "Propionibacterium acnes bacteria flourish within clogged pores, causing inflammation.",
      },
      {
        title: "Hormonal Changes: ",
        text: "Fluctuations in hormones, such as during puberty or menstrual cycles, can trigger acne by increasing oil production.",
      },
      {
        title: null,
        text: "Understanding these symptoms and causes is essential in adopting effective skincare practices and seeking appropriate treatment to manage acne.",
      },
    ],
    treatments: [
      {
        que: "1.	Topical Retinoids ",
        text: "Prescription or over-the-counter topical retinoids like tretinoin, adapalene, and tazarotene help unclog pores, reduce inflammation, and promote skin cell turnover, aiding in the prevention of acne.",
      },
      {
        que: "2.	Topical Antibiotics ",
        text: "Topical antibiotics such as clindamycin or erythromycin are used to reduce the population of acne-causing bacteria on the skin's surface, helping to minimize inflammation and breakouts.",
      },
      {
        que: "3.	Oral Antibiotics ",
        text: "In cases of moderate to severe acne, oral antibiotics like doxycycline, minocycline, or tetracycline may be prescribed to address bacterial overgrowth and inflammation from within.",
      },
    ],
    // slugText:
    //   "It's important to consult a healthcare provider or dermatologist for personalized treatment recommendations, as the appropriate approach depends on the severity and type of acne.",
    onlyDesc: null,
  },
  {
    title: "Male hair loss",
    symptoms: [
      `Receding Hairline: Hairline gradually moves backward, often forming an "M" shape.`,
      `Thinning Crown: Hair at the crown of the head gradually becomes thinner.`,
      `Bald Spots: Hair loss leads to visible bald spots or patches.`,
    ],
    benefits: [
      "Hair Regrowth: Effective treatments can stimulate hair regrowth in affected areas.",
      "Improved Confidence: Restored hair can boost self-esteem and confidence.",
      "Prevention of Further Loss: Treatment can help slow or halt the progression of hair loss, preventing more extensive balding.",
    ],
    causes: [
      {
        title: "Genetics: ",
        text: "Hereditary factors play a significant role in male pattern hair loss. If you have a family history of baldness, you are more likely to experience it yourself.",
      },
      {
        title: "Hormones: ",
        text: "Hormones, particularly dihydrotestosterone (DHT), contribute to the shrinking of hair follicles over time, leading to thinner and shorter hair strands.",
      },
      {
        title: "Age: ",
        text: "As men age, hair follicles can become more sensitive to hormonal changes, leading to gradual hair thinning and loss.",
      },
    ],
    treatments: [],
    // slugText:
    //   "Consulting a healthcare professional or dermatologist is important for personalized guidance on treating male pattern hair loss and determining the most suitable approach.",
    onlyDesc: null,
  },
  {
    title: "Dandruff",
    symptoms: [
      "White, flaky skin on the scalp and hair.",
      "Itching and irritation of the scalp.",
      "Dryness or greasiness of the scalp.",
    ],
    benefits: [],
    causes: [
      {
        title: "Yeast Overgrowth:",
        text: "Malassezia, a type of yeast that normally lives on the scalp, can overgrow and lead to dandruff when it feeds on excess oils.",
      },
      {
        title: "Seborrheic Dermatitis: ",
        text: "A common skin condition causing redness, itching, and flaking in areas with a lot of oil glands, such as the scalp.",
      },
      {
        title: "Improper Hair Care: ",
        text: "Infrequent shampooing or using harsh hair products can lead to scalp buildup and dandruff.",
      },
      {
        title: null,
        text: "Understanding these symptoms and causes can help individuals manage dandruff more effectively and seek appropriate treatments if necessary.",
      },
    ],
    treatments: [
      {
        que: "1.	Anti-Dandruff Shampoos ",
        text: "Over-the-counter or prescription shampoos containing active ingredients like zinc pyrithione, ketoconazole, or selenium sulfide can help control dandruff by reducing yeast overgrowth and flaking.",
      },
      {
        que: "2.	Tar-Based Shampoos ",
        text: "Shampoos with coal tar as an active ingredient can help slow skin cell turnover and reduce flaking.",
      },
      {
        que: "3.	Regular Shampooing and Scalp Care ",
        text: "Consistently washing the hair and scalp with a gentle shampoo can help manage dandruff by keeping the scalp clean and minimizing oil buildup.",
      },
    ],
    // slugText:
    //   "It's advisable to consult a dermatologist if dandruff persists or is severe, as they can recommend the most suitable treatment based on the underlying cause and individual needs.",
    onlyDesc: null,
  },

  {
    title: "Eczema",
    symptoms: [
      "Itchy, red, inflamed skin.",
      "Dry, sensitive skin prone to cracking.",
      "Thickened, scaly patches.",
      "Small, raised bumps that may leak fluid and crust over.",
      "Skin discoloration due to irritation.",
    ],
    benefits: [],
    causes: [
      {
        title: "Genetics: ",
        text: "A family history of eczema or other allergic conditions can increase the risk of developing it.",
      },
      {
        title: "Immune System Dysregulation: ",
        text: "Eczema involves an immune system response to triggers that results in inflammation and skin irritation.",
      },
      {
        title: "Environmental Factors: ",
        text: "Exposure to irritants, allergens, dry air, or harsh chemicals can trigger or worsen eczema symptoms.",
      },
      {
        title: null,
        text: "Recognizing these symptoms and causes can help individuals manage eczema effectively by avoiding triggers and seeking appropriate medical treatment.",
      },
    ],
    treatments: [
      {
        que: "1.	Topical Corticosteroids ",
        text: "These anti-inflammatory creams or ointments are commonly prescribed to reduce redness, itching, and inflammation during eczema flare-ups.",
      },
      {
        que: "2.	Topical Calcineurin Inhibitors ",
        text: "Prescription creams like tacrolimus and pimecrolimus help modulate the immune response and reduce eczema symptoms without the side effects associated with corticosteroids.",
      },
      {
        que: "3.	Emollients/Moisturizers ",
        text: "Regular application of emollients and moisturizers helps maintain skin hydration, reduces dryness, and minimizes the risk of eczema flare-ups.",
      },
    ],
    // slugText:
    //   "It's important to consult a dermatologist or healthcare provider for proper diagnosis and personalized treatment recommendations based on the severity and type of eczema.",
    onlyDesc: null,
  },
  {
    title: "Rosacea",
    symptoms: [
      "Persistent facial redness, often resembling a blush or sunburn.",
      "Visible blood vessels (telangiectasia) on the face.",
      "Papules and pustules that resemble acne.",
      "Thickening of the skin, particularly around the nose (rhinophyma).",
      "Eye irritation and dryness (ocular rosacea).",
    ],
    benefits: [],
    causes: [
      {
        title: "Vascular Issues: ",
        text: "Abnormalities in blood vessels that cause flushing and redness.",
      },
      {
        title: "Inflammatory Response: ",
        text: "An immune system response that triggers inflammation and skin symptoms.",
      },
      {
        title: "Genetics: ",
        text: "Family history of rosacea can increase the risk of developing the condition.",
      },
      {
        title: null,
        text: "Understanding these symptoms and causes can aid in early diagnosis and appropriate management of rosacea through lifestyle adjustments and medical treatments.",
      },
    ],
    treatments: [
      {
        que: "1.	Topical Creams ",
        text: "Prescription or over-the-counter topical creams containing ingredients like metronidazole, azelaic acid, or sulfur can help reduce redness and inflammation associated with rosacea.",
      },
      {
        que: "2.	Oral Antibiotics ",
        text: "Oral antibiotics like doxycycline, minocycline, or tetracycline may be prescribed to control inflammation and address the underlying bacteria that contribute to rosacea symptoms.",
      },
      {
        que: "3.	Laser or Light Therapies ",
        text: "Various laser or light-based treatments, such as intense pulsed light (IPL) therapy, can help reduce visible blood vessels and redness associated with rosacea.",
      },
    ],
    // slugText:
    //   "Consulting a dermatologist is essential for a proper diagnosis and personalized treatment plan, as rosacea can vary widely in its presentation and severity.",
    onlyDesc: null,
  },
  {
    title: "Athlete's foot",
    symptoms: [
      "Itching, burning, and stinging sensations on the feet, especially between the toes.",
      "Redness and inflammation of the affected skin.",
      "Peeling, cracking, or flaking skin on the feet.",
      "Blisters or sores that may ooze or crust.",
    ],
    benefits: [],
    causes: [
      {
        title: "Fungal Infection: ",
        text: "Athlete's foot is primarily caused by fungal growth, often from the group of fungi known as dermatophytes.",
      },
      {
        title: "Warm and Humid Environments: ",
        text: "Fungi thrive in warm, moist areas, making locker rooms, public showers, and swimming pools common sources of infection.",
      },
      {
        title: "Poor Foot Hygiene: ",
        text: "Infrequent washing, inadequate drying, and sharing shoes or towels can contribute to the spread of the infection.",
      },
      {
        title: null,
        text: "Recognizing these symptoms and causes can help individuals take preventive measures and seek appropriate treatments to manage athlete's foot effectively.",
      },
    ],
    treatments: [
      {
        que: "1.	Antifungal Creams ",
        text: "Over-the-counter or prescription antifungal creams containing ingredients like clotrimazole, terbinafine, or miconazole can effectively treat athlete's foot by targeting the underlying fungal infection.",
      },
      {
        que: "2.	Antifungal Powders or Sprays ",
        text: "These products can help keep the feet dry and prevent moisture buildup, creating an environment less conducive to fungal growth.",
      },
      {
        que: "3.	Oral Antifungal Medications ",
        text: "In severe cases or when topical treatments aren't effective, a healthcare provider may prescribe oral antifungal medications to treat athlete's foot from within the body.",
      },
    ],
    // slugText:
    //   "It's important to practice good foot hygiene, keep the feet dry, and avoid sharing shoes or towels to prevent and manage athlete's foot. If symptoms persist or worsen, consulting a healthcare professional is recommended.",
    onlyDesc: null,
  },
  {
    title: "Nail infection",
    benfits: [],
    symptoms: [
      "Swelling, redness, and pain around the nail.",
      "Pus or discharge from the infected area.",
      "Changes in nail shape and color.",
    ],
    causes2: [
      {
        title: "Herpes Simplex Virus (HSV): ",
        text: "Typically HSV-1 or HSV-2, transmitted through direct contact with infected skin or mucous membranes.",
      },
      {
        title: "Skin-to-Skin Contact: ",
        text: "Exposure to someone with active herpes lesions.",
      },
      {
        title: "Compromised Immune System: ",
        text: "Weakened immune response can increase susceptibility to viral infections.",
      },
    ],
    causes3: [
      {
        title: "Fungal Growth: ",
        text: "Dermatophytes, yeasts, or molds infect the nail.",
      },
      {
        title: "Warm and Moist Environments: ",
        text: "Fungi thrive in damp conditions.",
      },
      {
        title: "Injury or Trauma: ",
        text: "Nail injuries can create openings for fungal entry.",
      },
    ],
    causes: [
      {
        title: "Injury: ",
        text: "Cuts or wounds around the nail can introduce bacteria.",
      },
      {
        title: "Poor Nail Hygiene:",
        text: "Lack of proper nail care and cleaning.",
      },
      {
        title: "Underlying Health Conditions: ",
        text: "Conditions like diabetes can increase the risk of bacterial nail infections.",
      },
    ],
    symptoms2: [
      "Viral Nail Infection (Herpetic Whitlow) Symptoms",
      "Painful blisters or sores around the nail.",
      "Redness and inflammation of the affected area.",
      "Itching and discomfort",
    ],
    symptoms3: [
      "Fungal Nail Infection (Onychomycosis) Symptoms",
      "Thickening, discoloration, and distortion of the nail.",
      "Brittle or crumbling nail texture.",
      "Separation of the nail from the nail bed.",
    ],

    treatments: [
      "Bacterial Nail Infection",
      {
        que: "1.	Oral Antibiotics ",
        text: "Prescription antibiotics are commonly used to treat bacterial nail infections, especially those caused by bacteria like Staphylococcus or Streptococcus.",
      },
      {
        que: "2.	Antibacterial Ointments",
        text: "Topical antibiotics, such as ointments or creams, can be applied to the affected area to help eliminate bacterial growth.",
      },
      {
        que: "3.	Drainage or Debridement",
        text: "In some cases, a healthcare provider may need to drain pus or remove affected tissue to promote healing.",
      },
    ],
    treatments2: [
      "Viral Nail Infection (Herpetic Whitlow)",
      {
        que: "1.	Antiviral Medications ",
        text: "Prescription antiviral medications like acyclovir, valacyclovir, or famciclovir can help manage viral nail infections, such as herpetic whitlow.",
      },
      {
        que: "2.	Topical Antiviral Creams",
        text: "For mild cases, topical antiviral creams may be recommended to alleviate symptoms and promote healing.",
      },
      {
        que: "3.	Pain Relief ",
        text: "Over-the-counter pain relievers can help manage discomfort associated with viral nail infections.",
      },
    ],
    treatments3: [
      "Fungal Nail Infection (Onychomycosis)",
      {
        que: "1.	Oral Antifungal Medications ",
        text: "Prescription oral antifungal medications like terbinafine or itraconazole are often used to treat fungal nail infections.",
      },
      {
        que: "2.	Topical Antifungal Treatments ",
        text: "Prescription or over-the-counter antifungal nail lacquers, creams, or ointments can be applied directly to the affected nail.",
      },
      {
        que: "3.	Laser Therapy ",
        text: "Some cases of fungal nail infections may respond to laser treatments that target the fungal infection in the nail bed.",
      },
    ],
    // slugText2:
    //   "Recognizing these symptoms and causes is essential for seeking appropriate treatment and preventing the spread of infections.",
    onlyDesc: null,
    afterCause:
      "Consulting a healthcare provider is crucial for proper diagnosis and treatment of nail infections, as the appropriate approach varies based on the type and severity of the infection.",
  },
  {
    title: "Shingles",
    symptoms: [
      "Painful, tingling, or burning sensation usually on one side of the body or face.",
      "Red rash that develops into fluid-filled blisters.",
      "Itching, sensitivity to touch, and discomfort.",
      "Flu-like symptoms, including fever, headache, and fatigue.",
      "Numbness or nerve-related pain in the affected area.",
    ],
    benefits: [],
    causes: [
      {
        title: "Varicella-Zoster Virus (VZV): ",
        text: "Reactivation of the dormant chickenpox virus causes shingles.",
      },
      {
        title: "Weakened Immune System: ",
        text: "Immune decline due to age, stress, illness, or medication can trigger reactivation.",
      },
      {
        title: "Age:",
        text: "Risk increases with age, as the immune system's ability to keep the virus in check weakens.",
      },
      {
        title: null,
        text: "Understanding these symptoms and causes can help individuals recognize and manage shingles effectively, seeking prompt medical attention for appropriate treatment.",
      },
    ],
    treatments: [
      {
        que: "1.	Antiviral Medications:",
        text: "Prescription antiviral drugs like acyclovir, valacyclovir, or famciclovir can help reduce the severity and duration of shingles symptoms if taken within 72 hours of rash onset.",
      },
      {
        que: "2.	Pain Relievers ",
        text: "Over-the-counter pain relievers or prescription medications like ibuprofen or acetaminophen can help manage the pain associated with shingles.",
      },
      {
        que: "3.	Antiviral Creams ",
        text: "Topical antiviral creams, such as acyclovir or lidocaine, can be applied directly to the rash to help alleviate discomfort and reduce pain.",
      },
    ],
    // slugText:
    //   "It's important to consult a healthcare provider for proper diagnosis and treatment recommendations if you suspect you have shingles, especially to address potential complications and manage symptoms effectively.",
    onlyDesc: null,
  },
  {
    title: "Rash",
    symptoms: [
      "Redness, warmth, and tenderness of the skin.",
      "Formation of pus-filled bumps or blisters.",
      "Itching, pain, or discomfort.",
    ],
    symptoms2: [
      "Viral Rash Symptoms",
      "Red or pink rash that may be flat or raised.",
      "Blisters, ulcers, or sores.",
      "Fever, fatigue, and flu-like symptoms.",
    ],
    symptoms3: [
      "Fungal Rash Symptoms",
      "Red or pink rash with distinct edges.",
      "Itching, burning, or stinging sensations.",
      "Scaling, flaking, or peeling skin.",
    ],
    benefits: [],
    causes: [
      {
        title: "Staphylococcus or Streptococcus Bacteria:",
        text: "Common bacteria that can cause skin infections.",
      },
      {
        title: "Scratches or Cuts: ",
        text: "Bacteria can enter the body through open wounds.",
      },
      {
        title: "Poor Hygiene: ",
        text: "Insufficient cleanliness can lead to bacterial overgrowth.",
      },
    ],
    causes2: [
      {
        title: "Viral Infections: ",
        text: "Various viruses, including herpes viruses, can lead to viral rashes.",
      },
      {
        title: "Direct Contact: ",
        text: "Transmission through physical contact or respiratory droplets.",
      },
      {
        title: "Weakened Immune System: ",
        text: "Immune suppression increases susceptibility to viral infections.",
      },
    ],
    causes3: [
      {
        title: "Fungi:",
        text: "Dermatophytes, yeasts, and molds can cause fungal skin infections.",
      },
      {
        title: "Warm and Moist Environments:",
        text: "Fungi thrive in damp conditions.",
      },
      {
        title: "Direct Contact: ",
        text: "Transmission through contact with infected surfaces or individuals.",
      },
    ],
    treatments: [
      "Bacterial Rash Treatment",
      {
        que: "1.	Topical Antibiotics ",
        text: "Prescription or over-the-counter topical antibiotics can be applied directly to the affected area to target and eliminate bacterial infection.",
      },
      {
        que: "2.	Oral Antibiotics ",
        text: "In more severe cases, oral antibiotics may be prescribed to address the bacterial infection from within the body.",
      },
      {
        que: "3.	Proper Hygiene ",
        text: "Keeping the affected area clean and dry is important to prevent bacterial growth and spread.",
      },
    ],
    treatments2: [
      "Viral Rash Treatment",
      {
        que: "1.	Antiviral Medications ",
        text: "Prescription antiviral drugs are used to treat viral rashes caused by infections like herpes viruses (e.g., cold sores, shingles).",
      },
      {
        que: "2.	Symptomatic Relief ",
        text: "Over-the-counter pain relievers and topical creams can help alleviate discomfort and itching associated with viral rashes.",
      },
      {
        que: "3.	Hydration and Rest ",
        text: "Adequate hydration and rest can support the body's immune response in combating viral infections.",
      },
    ],
    treatments: [
      "Fungal Rash Treatment",
      {
        que: "1.	Antifungal Creams",
        text: "Prescription or over-the-counter antifungal creams containing ingredients like clotrimazole, miconazole, or ketoconazole can help treat fungal rashes by targeting the underlying infection.",
      },
      {
        que: "2.	Oral Antifungal Medications",
        text: " For more severe or persistent fungal rashes, oral antifungal medications may be prescribed to address the infection from within the body.",
      },
      {
        que: "3.	Hygiene and Prevention",
        text: "3.	Hygiene and Prevention: ",
      },
    ],
    // slugText:
    //   "Recognizing these symptoms and causes helps in understanding the nature of rashes and seeking appropriate medical attention for accurate diagnosis and treatment.",
    // slugText2:
    //   "Proper diagnosis by a healthcare provider is essential for identifying the cause of the rash and determining the most suitable treatment approach",
    onlyDesc: null,
  },
  {
    title: "Hidradenitis Suppurativa",
    symptoms: [
      "Painful, inflamed bumps or boils that develop in areas with sweat glands (armpits, groin, buttocks).",
      "Recurrent abscesses that may rupture and drain pus.",
      "Formation of tunnels beneath the skin (sinus tracts) connecting affected areas.",
    ],
    benefits: [],
    causes: [
      {
        title: "1.	Hair Follicle Blockage: ",
        text: "Obstruction of hair follicles leads to inflammation and bacterial overgrowth.",
      },
      {
        title: "2.	Immune System Dysfunction: ",
        text: "An abnormal immune response contributes to the chronic inflammation seen in hidradenitis suppurativa.",
      },
      {
        title: "3.	Genetic Predisposition: ",
        text: "Family history of the condition increases the likelihood of developing it.",
      },
    ],
    treatments: [
      {
        que: "1.	Topical Treatments",
        text: "Prescription or over-the-counter topical creams or ointments containing antibiotics, corticosteroids, or retinoids can help manage inflammation and infection in mild cases.",
      },

      {
        que: "2.	Oral Antibiotics ",
        text: "Prescription antibiotics, such as tetracycline or clindamycin, are often used to treat bacterial infections and control inflammation associated with hidradenitis suppurativa.",
      },

      {
        que: "3.	Biologic Therapies ",
        text: "In more severe cases, biologic medications targeting specific immune pathways, like tumor necrosis factor (TNF) inhibitors, may be prescribed to help reduce inflammation and prevent flare-ups.",
      },
    ],
    // slugTex/t:
    //   "Understanding these symptoms and causes is essential for early diagnosis and proper management of hidradenitis suppurativa.",
    // slugText2:
    //   "It's crucial to consult a dermatologist or healthcare provider for personalized treatment recommendations based on the severity and progression of the condition.",
    onlyDesc: null,
  },
  {
    title: "High blood pressure",
    symptoms: [
      `Often referred to as the "silent killer," high blood pressure (hypertension) typically has no noticeable symptoms.`,
      `In severe cases, symptoms like headaches, shortness of breath, chest pain, and visual changes may occur.`,
    ],
    benefits: [],
    causes: [
      {
        title: "Lifestyle Factors: ",
        text: "Unhealthy diet, lack of physical activity, excessive alcohol consumption, and smoking contribute to high blood pressure.",
      },
      {
        title: "Genetics:",
        text: "Family history of hypertension increases the risk.",
      },
      {
        title: "Chronic Conditions: ",
        text: "Conditions like obesity, diabetes, and kidney disease can elevate blood pressure.",
      },
    ],
    risks: [
      {
        title: "Cardiovascular Disease: ",
        text: "Long-term high blood pressure can damage blood vessels, leading to heart disease, heart attacks, and stroke.",
      },
      {
        title: "	Kidney Damage: ",
        text: "Hypertension can harm the kidneys, affecting their ability to filter waste and regulate blood pressure.",
      },
      {
        title: "Vision Impairment: ",
        text: "It can damage blood vessels in the eyes, potentially leading to vision loss.",
      },
    ],
    treatments: [
      {
        que: "1.	Lifestyle Modifications",
        text: "Healthy lifestyle changes, including adopting a balanced diet (such as the DASH diet), engaging in regular physical activity, maintaining a healthy weight, reducing salt intake, moderating alcohol consumption, and quitting smoking can significantly lower blood pressure. These modifications promote overall cardiovascular health and reduce the risk of related complications.",
      },
      {
        que: "2.	Antihypertensive Medications",
        text: "Prescription medications such as angiotensin-converting enzyme (ACE) inhibitors, angiotensin II receptor blockers (ARBs), beta-blockers, diuretics, and calcium channel blockers help lower blood pressure by targeting different mechanisms in the body. Effective medication management can reduce the risk of heart attacks, strokes, and other cardiovascular events associated with hypertension.",
      },
      {
        que: "3.	Combination Therapy",
        text: "Combining different classes of antihypertensive medications can achieve better blood pressure control, especially for individuals with more severe hypertension. This approach enhances the effectiveness of treatment while minimizing potential side effects.",
      },
    ],
    // slugText2:
    //   "It's important to work closely with a healthcare provider to determine the most appropriate treatment strategy based on individual health status, risk factors, and response to treatment.00",
    // slugText:
    //   "Recognizing these risks and addressing the causes through lifestyle changes and medical interventions can help prevent or manage high blood pressure effectively.",
    onlyDesc: null,
  },
  {
    title: "Asthma/COPD",
    symptoms: [
      "Chronic cough with mucus production.",
      "Shortness of breath, especially during physical activity.",
      "Wheezing and chest tightness.",
      "Frequent respiratory infections.",
      "Fatigue and reduced exercise tolerance.",
    ],
    symptoms2: [
      "Untreated Asthma Symptoms",
      "Recurrent episodes of wheezing.",
      "Coughing, especially at night or early in the morning.",
      "Shortness of breath and chest tightness.",
      "Symptoms worsened by triggers such as allergens, cold air, or exercise.",
    ],
    risks2: [
      {
        title: "Asthma Attacks: ",
        text: "Untreated asthma increases the risk of severe asthma attacks, potentially leading to life-threatening situations.",
      },
      {
        title: "Poor Quality of Life: ",
        text: "Unmanaged symptoms can significantly impact daily activities and overall well-being.",
      },
      {
        title: "Lung Function Decline: ",
        text: "Chronic inflammation and untreated asthma can cause long-term damage to the airways and lung function",
      },
    ],
    treatments2: [
      "Chronic Obstructive Pulmonary Disease (COPD)",
      {
        que: "1.	Bronchodilators (Beta-Agonists and Anticholinergics)",
        text: " Bronchodilators help relax and widen the airways, making it easier to breathe. They provide symptom relief by reducing shortness of breath and improving lung function in COPD patients.",
      },
      {
        que: "2.	Inhaled Corticosteroids",
        text: " These medications help reduce airway inflammation and prevent exacerbations. Inhaled corticosteroids are particularly useful in COPD patients with frequent exacerbations.",
      },
      {
        que: "3.	Pulmonary Rehabilitation",
        text: " Pulmonary rehabilitation programs involve exercise training, education, and counseling to improve lung function, enhance exercise tolerance, and enhance overall quality of life for COPD patients.",
      },
    ],
    treatments3: [
      "Asthma",
      {
        que: "1.	Inhaled Corticosteroids",
        text: " These medications are the cornerstone of asthma management, reducing airway inflammation and preventing asthma symptoms. They help control symptoms, improve lung function, and prevent asthma exacerbations.",
      },
      {
        que: "2.	Bronchodilators (Short-Acting Beta-Agonists and Long-Acting Beta-Agonists)",
        text: " Bronchodilators provide rapid relief of acute asthma symptoms by relaxing the airway muscles and improving breathing. Long-acting bronchodilators are used for maintenance and prevention of symptoms.",
      },
      {
        que: "3.	Leukotriene Modifiers",
        text: " Leukotriene modifiers block chemicals that cause inflammation, helping to reduce swelling and mucus production in the airways. They are often used as an alternative treatment for asthma control.",
      },
    ],
    benefits: [],
    risks: [
      {
        title: "Exacerbations: ",
        text: "Untreated COPD increases the risk of frequent and severe exacerbations, leading to hospitalization.",
      },
      {
        title: "Lung Function Decline: ",
        text: "Progressive damage to the airways and lung tissues worsens over time, causing reduced lung function.",
      },
      {
        title: "Cardiovascular Complications: ",
        text: "COPD is associated with an increased risk of heart problems, including heart attacks and arrhythmias.",
      },
    ],
    causes: [
      {
        title: "Smoking: ",
        text: "The primary cause of COPD is long-term exposure to tobacco smoke.",
      },
      {
        title: "Environmental Exposure: ",
        text: "Prolonged exposure to air pollutants, chemicals, and dust can contribute.",
      },
      {
        title: "Genetic Factors: ",
        text: "A small percentage of COPD cases are linked to genetic factors, such as alpha-1 antitrypsin deficiency.",
      },
    ],
    causes2: [
      {
        title: "Allergens:",
        text: "Exposure to allergens like pollen, dust mites, pet dander, and mold can trigger asthma symptoms.",
      },
      {
        title: "Environmental Irritants: ",
        text: "Tobacco smoke, air pollution, strong odors, and fumes can exacerbate asthma.",
      },
      {
        title: "Genetic Predisposition: ",
        text: "Family history of asthma increases the likelihood of developing the condition.",
      },
    ],
    treatments: [],
    // afterCause:
    //   "Recognizing these symptoms, risks, and causes emphasizes the importance of early diagnosis and appropriate management for COPD and asthma.",
    // slugTextBottom:
    //   "Individual treatment plans should be tailored by healthcare professionals based on the severity of the condition, patient preferences, and response to therapy. Regular communication with healthcare providers is important for optimal disease management.",
    onlyDesc: null,
  },
  {
    title: "Hypothyroidism",
    symptoms: [
      "Fatigue and low energy levels.",
      "Weight gain and difficulty losing weight.",
      "Cold intolerance and sensitivity to cold temperatures.",
      "Dry skin and brittle nails.",
      "Constipation and digestive issues.",
    ],
    risks: [
      {
        title: "Cardiovascular Complications: ",
        text: "	Untreated hypothyroidism increases the risk of heart disease, including high blood pressure and elevated cholesterol levels.",
      },
      {
        title: "Mental Health Issues: ",
        text: "Depression, anxiety, and cognitive impairment can result from untreated hypothyroidism.",
      },
      {
        title: "Myxedema: ",
        text: "Severe untreated hypothyroidism can lead to myxedema, a rare but life-threatening condition causing low body temperature, confusion, and unconsciousness.",
      },
    ],
    benefits: [],
    causes: [
      {
        title: "Autoimmune Thyroiditis (Hashimoto's Disease): ",
        text: "The immune system attacks the thyroid gland, leading to decreased hormone production.",
      },
      {
        title: "Iodine Deficiency: ",
        text: "Inadequate dietary intake of iodine, essential for thyroid hormone synthesis, can cause hypothyroidism.",
      },
      {
        title: "Thyroidectomy or Radioactive Iodine Treatment: ",
        text: "Surgical removal of the thyroid gland or radioactive iodine therapy can lead to reduced hormone production.",
      },
    ],
    treatments: [
      {
        que: "1.	Synthetic Thyroid Hormone Replacement (Levothyroxine)",
        text: "Levothyroxine is a synthetic form of the thyroid hormone thyroxine (T4). It effectively restores thyroid hormone levels, alleviating hypothyroidism symptoms such as fatigue, weight gain, and cold intolerance. Proper dosage and consistent use help maintain metabolism, energy levels, and overall well-being.",
      },
      {
        que: "2.	Regular Monitoring and Adjustments",
        text: "Ongoing monitoring of thyroid hormone levels and adjustments to medication dosage ensure optimal thyroid function and symptom management. Regular check-ups prevent under- or over-treatment.",
      },
      {
        que: "3.	Lifestyle Modifications",
        text: "Maintaining a balanced diet, regular exercise, managing stress, and ensuring adequate sleep contribute to overall health and complement thyroid hormone replacement therapy.",
      },
    ],
    // slugText2:
    //   "Individual treatment plans should be tailored by healthcare professionals based on the patient's thyroid hormone levels, symptoms, and overall health. Regular communication with healthcare providers is essential to achieve the best outcomes in managing hypothyroidism.",
    // slugText:
    //   "Understanding these symptoms, risks, and causes underscores the importance of early diagnosis and appropriate treatment for hypothyroidism.",
    onlyDesc: null,
  },
  {
    title: "Migraine",
    symptoms: [
      "Severe throbbing or pulsating headache, often on one side of the head.",
      "Sensitivity to light (photophobia) and sound (phonophobia).",
      "Nausea and vomiting.",
      "Aura: Visual disturbances, such as flashing lights or zigzag lines, may occur before or during the headache for some individuals",
    ],
    benefits: [],
    causes: [
      {
        title: "Genetic Predisposition: ",
        text: "Family history of migraines increases the likelihood of experiencing them.",
      },
      {
        title: "Neurochemical Changes: ",
        text: "Abnormalities in brain chemicals like serotonin and dopamine play a role in migraine onset.",
      },
      {
        title: "Triggers: ",
        text: "Certain factors like stress, hormonal changes, certain foods (e.g., chocolate, caffeine), lack of sleep, and environmental changes can trigger migraines in susceptible individuals.",
      },
    ],
    treatments: [
      {
        que: "1.	Acute Migraine Medications",
        text: "Over-the-counter nonsteroidal anti-inflammatory drugs (NSAIDs) like ibuprofen or prescription medications like triptans can provide relief during a migraine attack by reducing pain and other associated symptoms.",
      },
      {
        que: "2.	Preventive Medications",
        text: "For individuals with frequent or severe migraines, preventive medications such as beta-blockers, antiepileptic drugs, or certain antidepressants can help reduce the frequency, severity, and duration of migraine attacks.",
      },
      {
        que: "3.	Lifestyle Modifications and Triggers Management",
        text: "Identifying and avoiding triggers (e.g., certain foods, stress, lack of sleep) and adopting a consistent routine involving regular meals, hydration, exercise, and sleep can contribute to better migraine management.",
      },
      {
        que: "4.	CGRP Monoclonal Antibodies",
        text: "A newer class of medications that target calcitonin gene-related peptide (CGRP) can prevent migraines by reducing their frequency and intensity. These medications are specifically designed for migraine prevention.",
      },
    ],
    // slugText:
    //   "Recognizing these symptoms and triggers is essential for managing and preventing migraine headaches effectively.",
    // slugText2:
    //   "Individual treatment plans should be developed in collaboration with healthcare professionals, considering the frequency, severity, and triggers of migraine attacks, as well as the patient's overall health and preferences. Regular communication with healthcare providers is important to ensure effective migraine management.",
    onlyDesc: null,
  },

  {
    title: "High cholesterol",
    symptoms: [
      "High cholesterol typically doesn't cause noticeable symptoms. It's detected through blood tests measuring cholesterol levels, including LDL (low-density lipoprotein) and HDL (high-density lipoprotein) cholesterol.",
    ],
    benefits: [],
    causes: [
      {
        title: "Unhealthy Diet:",
        text: "Consuming foods high in saturated and trans fats contributes to elevated cholesterol levels.",
      },
      {
        title: "Lack of Physical Activity: ",
        text: "Sedentary lifestyle can lead to imbalances in cholesterol.",
      },
      {
        title: "Genetics: ",
        text: "Family history and genetic factors play a role in cholesterol levels.",
      },
    ],
    risks: [
      {
        title: "Cardiovascular Disease: ",
        text: "Elevated LDL cholesterol contributes to the buildup of plaque in arteries, increasing the risk of heart attacks and strokes.",
      },
      {
        title: "Atherosclerosis: ",
        text: "Plaque accumulation narrows and hardens arteries, restricting blood flow.",
      },
      {
        title: "Hypertension: ",
        text: "High cholesterol often coexists with high blood pressure, compounding cardiovascular risks.",
      },
      {
        title: "Peripheral Artery Disease: ",
        text: "Reduced blood flow to extremities leads to pain, numbness, and reduced mobility.",
      },
      {
        title: "Heart Failure: ",
        text: "Cholesterol-related arterial damage can weaken the heart muscle over time.",
      },
    ],
    treatments: [
      {
        que: "1.	Statins",
        text: "Statins are widely prescribed to lower LDL cholesterol levels by inhibiting its production in the liver. They have been shown to reduce the risk of cardiovascular events, heart attacks, and strokes. Statins also have modest effects in increasing HDL cholesterol levels.",
      },
      {
        que: "2. Dietary Changes",
        text: "Adopting a heart-healthy diet low in saturated and trans fats while rich in fiber, fruits, vegetables, and whole grains can significantly lower cholesterol levels. This approach supports overall cardiovascular health.",
      },
      {
        que: "3. Regular Exercise",
        text: "Engaging in regular physical activity helps raise HDL cholesterol levels and promotes weight management. It also contributes to improved heart function and vascular health.",
      },
      {
        que: "4. Other Medications (Ezetimibe, PCSK9 Inhibitors, etc.)",
        text: "These medications are prescribed when statins alone aren't sufficient. They work by different mechanisms to lower LDL cholesterol levels and reduce cardiovascular risks.",
      },
    ],
    // slugText:
    //   "Effective management includes lifestyle changes (diet, exercise) and, when necessary, medications (statins, etc.) to lower cholesterol levels and mitigate cardiovascular risks.",
    // slugText2:
    //   "Individual treatment plans should be tailored based on the patient's cholesterol levels, risk factors, and overall health. Collaborating with healthcare professionals is crucial to achieve the best outcomes in managing hypercholesterolemia and reducing cardiovascular risks.",
    onlyDesc: null,
  },

  {
    title: "Diabetes",
    symptoms: [
      "Increased thirst and hunger.",
      "Frequent urination.",
      "Fatigue and weakness.",
      "Blurred vision.",
      "Slow healing of cuts and bruises.",
      "Tingling or numbness in hands and feet.",
    ],
    benefits: [],
    causes: [
      {
        title: "Insulin Resistance: ",
        text: "Cells become less responsive to insulin, leading to elevated blood sugar levels.",
      },
      {
        title: "Obesity: ",
        text: "Excess body weight, especially around the abdomen, increases diabetes risk.",
      },
      {
        title: "Genetics: ",
        text: "Family history and genetic factors contribute to susceptibility.",
      },
    ],
    risks: [
      {
        title: "Cardiovascular Complications: ",
        text: "Uncontrolled diabetes significantly raises the risk of heart disease, stroke, and peripheral artery disease.",
      },
      {
        title: "Kidney Damage: ",
        text: "Prolonged high blood sugar damages the kidneys, leading to kidney disease.",
      },
      {
        title: "Nerve Damage (Neuropathy): ",
        text: "Diabetes-related nerve damage causes pain, numbness, and problems with digestion or sexual function.",
      },
      {
        title: "Vision Problems: ",
        text: "Diabetes can lead to diabetic retinopathy, causing vision loss or blindness.",
      },
      {
        title: "Foot Complications: ",
        text: "Poor circulation and nerve damage can lead to foot ulcers, infections, and even amputations.",
      },
    ],
    treatments2: [
      "Evidence-Based Medical Treatments",
      {
        que: "1.	Lifestyle Modifications (Diet, Exercise)",
        text: " A balanced diet, portion control, and regular physical activity help manage blood sugar levels, promote weight loss, and enhance overall well-being.",
      },
      {
        que: "2.	Oral Medications (Metformin, etc.)",
        text: " Oral medications help improve insulin sensitivity, reduce liver glucose production, and manage blood sugar levels.",
      },
      {
        que: "3.	Insulin Therapy",
        text: " For some, insulin injections or pumps are necessary to regulate blood sugar levels and prevent complications.",
      },
      {
        que: "4.	Glucagon-Like Peptide-1 (GLP-1) Agonists, SGLT2 Inhibitors, etc",
        text: " These medications, along with other classes, help manage blood sugar levels by targeting different pathways in the body.",
      },
    ],
    // slugText:
    //   "Effective management involves a comprehensive approach, including medical interventions, lifestyle changes, blood sugar monitoring, and regular medical check-ups. Collaboration with healthcare professionals is crucial for personalized treatment and complication prevention.",
    onlyDesc: null,
  },
  {
    title: "Weight Loss",
    symptoms: [],
    introText:
      "Our clinic offers comprehensive, personalized care for your weight loss journey.",
    suggestions: [
      {
        heading: "Your Initial Assessment",
        points: [
          "When you first visit us, we'll start with a complete medical evaluation to understand your unique health profile, medical history, current conditions, and medications. This helps us gauge where you stand and how best to assist you.",
          "After this, you'll meet our licensed nutritionist. They will dive deep into your eating habits, food preferences, allergies, intolerances, and nutritional needs. This holistic view is essential to craft the best plan for you.",
        ],
      },
      {
        heading: "Your Personalized Diet Plan",
        points: [
          "Based on our combined insights, our nutritionist will design a weight loss diet plan tailored just for you. Rest assured, this plan will draw from the latest, evidence-backed weight loss diets, ensuring you get the most effective strategies for your needs.",
          "We'll also ensure your personalized diet aligns perfectly with any medical conditions or medications you might be on.",
        ],
      },
      {
        heading: "Ongoing Support and Adjustments",
        points: [
          "Your journey will include regular check-ins with our medical team and the nutritionist. We want to monitor your progress and ensure everything is going smoothly.",
          "If there's ever a need, based on your feedback or our observations, we'll adjust your diet plan to suit your journey better.",
        ],
      },
      {
        heading: "Exclusive Educational Workshops",
        points: [
          "Our nutritionist will also host monthly workshops where you can learn more about nutrition, the science behind our recommended diets, and tips to stick with them. It's a fantastic opportunity to gain knowledge and connect with others on similar journeys.",
        ],
      },
      {
        heading: "Collaborative Approach",
        points: [
          "I want to emphasize how closely our nutritionist and medical team collaborate. Regular team discussions ensure we're on the same page regarding your care. Our main aim is your well-being.",
        ],
      },
      {
        heading: "Your Feedback Matters",
        points: [
          "We always encourage you to share your thoughts, feedback, or any challenges you might be facing. This dialogue helps us continually refine and adjust our approach to best serve you.",
        ],
      },
    ],
    medications: [
      {
        heading: "Orlistat (Xenical, Alli)",
        points: [
          "Administration: Oral",
          "Mechanism: Reduces intestinal fat absorption by inhibiting pancreatic lipase.",
          "Contraindications: Chronic malabsorption syndrome, cholestasis, and hypersensitivity to orlistat. Also, use caution in patients with a history of kidney stones or hyperoxaluria.",
        ],
      },
      {
        heading: "Phentermine-Topiramate (Qsymia)",
        points: [
          "Administration: Oral",
          "Mechanism: Phentermine is an appetite suppressant, while topiramate is an antiepileptic drug with weight loss side effects.",
          "Contraindications: Pregnancy, glaucoma, hyperthyroidism, and recent (within 14 days) use of monoamine oxidase inhibitors (MAOIs). Caution in patients with tachycardia, heart disease, or a history of drug misuse.",
        ],
      },
      {
        heading: "Bupropion-Naltrexone (Contrave)",
        points: [
          "Administration: Oral",
          "Mechanism: Bupropion is an antidepressant that can decrease appetite, and naltrexone is used to treat opioid and alcohol dependence and reduce food cravings.",
          "Contraindications: Uncontrolled hypertension, seizure disorders, chronic opioid use, recent use of MAOIs, known allergy to any component, and patients undergoing abrupt discontinuation of alcohol, benzodiazepines, barbiturates, and antiepileptic drugs.",
        ],
      },
      {
        heading: "Liraglutide (Saxenda)",
        points: [
          "Administration: Subcutaneous injection",
          "Mechanism: GLP-1 receptor agonist increases feelings of fullness, delays stomach emptying, and may affect appetite-regulating hormones.",
          "Contraindications: Personal or family history of medullary thyroid carcinoma or Multiple Endocrine Neoplasia syndrome type 2 (MEN 2) and known hypersensitivity to Liraglutide or its components.",
        ],
      },
      {
        heading: "Semaglutide (Wegovy)",
        points: [
          "Administration: Subcutaneous injection",
          "Mechanism: GLP-1 receptor agonist. Works similarly to Liraglutide.",
          "Contraindications: Similar to Liraglutide, it should not be used by people with a personal or family history of medullary thyroid carcinoma or MEN 2.",
        ],
      },
    ],
    // slugText2:
    //   "This is a general overview, and there may be additional contraindications, interactions, or cautions for each medication and potential side effects. Before initiating any treatment, a comprehensive patient assessment and a discussion of the risks and benefits of the drug is essential.",
    // slugText1:
    //   "We are committed to your health and well-being. With our enhanced approach, I'm confident we can support you every step of your weight loss journey.",
    onlyDesc: null,
    suggestionsCost: [
      {
        heading: "Understanding the Costs",
        points: [
          "We believe in transparency and want you to be fully informed about the costs associated with your weight loss journey. The program fee is $499.00, which encompasses your consultation with our licensed nutritionist and a personalized exercise plan harmoniously integrated with any medications we recommend.",
          "Please be aware that the medications themselves are an additional cost and can range from $200 to $500 per month. This variation is due to the specific type and dose of medication prescribed to fit your needs best. For your convenience, we accept credit card payments, or you can opt for monthly bank draft payments if that suits you better.",
        ],
      },
    ],
  },
  {
    title: "Medical Aesthetics",
    symptoms: [],
    benefits: [],
    causes: [],
    treatments: [],
    slugText: "Coming Soon!",
    onlyDesc: null,
  },
  {
    title: "Mental health",
    introText: `Welcome to CareXPS, your pathway to mental well-being. Our boutique healthcare provider in Canada offers comprehensive care tailored to your mental health needs`,
    joiningDetails: [
      {
        title: "Online Registration:",
        text: "Begin your journey by signing up on our platform. Provide a concise health background to help us understand your needs better.",
      },
      {
        title: "Team Up:",
        text: "Get matched with one of our expert mental health physicians or nurse practitioners, ensuring you receive specialized therapeutic care tailored to you.",
      },
      {
        title: "First Counselor Session:",
        text: "Schedule your initial appointment with your dedicated mental health counsellor to set the foundation for your ongoing support.",
      },
      {
        title: "Consistent Communication:",
        text: "Maintain regular touchpoints with your counsellor and medical provider through our secure messaging system, face-to-face virtual meetings, or phone calls. This continuous connection ensures you always have the support you need.",
      },
    ],
    mental_Treatments: [
      {
        heading: `Depression:`,
        points: [],
      },
      {
        mh: true,
        heading: `Fluoxetine (Prozac)`,
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Concurrent use with MAO inhibitors, known hypersensitivity to fluoxetine—caution with liver disease, seizure disorders, and history of mania.",
        ],
      },
      {
        mh: true,
        heading: "Sertraline (Zoloft)",
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Concomitant use with pimozide or MAO inhibitors, known hypersensitivity to sertraline.",
        ],
      },
      {
        heading: "Bupropion (Wellbutrin",
        mh: true,
        points: [
          "Drug Class: Norepinephrine-Dopamine Reuptake Inhibitor (NDRI)",
          "Contraindications: Seizure disorders, history of anorexia/bulimia, abrupt discontinuation of alcohol or sedatives, MAO inhibitor use within the last 14 days.",
        ],
      },
      {
        heading: "Anxiety Disorders:",
        points: [],
      },
      {
        heading: "Paroxetine (Paxil)",
        mh: true,
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Concomitant use with thioridazine or MAO inhibitors, known hypersensitivity to paroxetine.",
        ],
      },
      {
        heading: "Diazepam (Valium)",
        mh: true,
        points: [
          "Drug Class: Benzodiazepine",
          "Contraindications: Myasthenia gravis, severe hepatic insufficiency, severe respiratory insufficiency, sleep apnea syndrome, and acute narrow-angle glaucoma.",
        ],
      },
      {
        heading: "Buspirone (Buspar)",
        mh: true,
        points: [
          "Drug Class: Anxiolytic (not a benzodiazepine)",
          "Drug Class: Anxiolytic (not a benzodiazepine).",
        ],
      },
      {
        heading: "OCD (Obsessive-Compulsive Disorder):",
        points: [],
      },
      {
        heading: "Fluvoxamine (Luvox)",
        mh: true,
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Concomitant use with MAO inhibitors, hypersensitivity to fluvoxamine.",
        ],
      },
      {
        heading: "Clomipramine (Anafranil)",
        mh: true,
        points: [
          "Drug Class: Tricyclic Antidepressant (TCA)",
          "Contraindications: Recent myocardial infarction, concurrent MAO inhibitor therapy, known hypersensitivity to clomipramine",
        ],
      },
      {
        heading: "Escitalopram (Lexapro)",
        mh: true,
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Concomitant use with MAO inhibitors, known hypersensitivity to escitalopram.",
        ],
      },
      {
        heading: "Acute Adjustment Disorders:",
        note: "(Note: Treatment usually involves psychotherapy, but some may benefit from medication.)",
        points: [],
      },
      {
        heading: "Duloxetine (Cymbalta)",
        mh: true,
        points: [
          "Drug Class: Serotonin-Norepinephrine Reuptake Inhibitor (SNRI)",
          "Contraindications: Uncontrolled narrow-angle glaucoma, MAO inhibitor use within the past 14 days.",
        ],
      },
      {
        heading: "Amitriptyline",
        mh: true,
        points: [
          "Drug Class: Tricyclic Antidepressant (TCA)",
          "Contraindications: Recent myocardial infarction, arrhythmias, and concurrent MAO inhibitor use.",
        ],
      },
      {
        heading: "Lorazepam (Ativan)",
        mh: true,
        points: [
          "Drug Class: Benzodiazepine",
          "Contraindications: Sleep apnea, severe respiratory failure, and myasthenia gravis.",
        ],
      },
      {
        heading: "Panic Disorder:",
        points: [],
      },
      {
        heading: "Alprazolam (Xanax)",
        mh: true,
        points: [
          "Drug Class: Benzodiazepine",
          "Contraindications: Concurrent ketoconazole and itraconazole therapy, narrow-angle glaucoma, known hypersensitivity.",
        ],
      },
      {
        heading: "Citalopram (Celexa)",
        mh: true,
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Concomitant use with MAO inhibitors, known hypersensitivity to citalopram.",
        ],
      },
      {
        heading: "Venlafaxine XR (Effexor XR)",
        mh: true,
        points: [
          "Drug Class: Selective Serotonin Reuptake Inhibitor (SSRI)",
          "Contraindications: Untreated narrow-angle glaucoma, MAO inhibitor use within the last 14 days.",
        ],
      },
    ],
    // slugText2:
    //   "It's essential to note that while these medications can be effective, they need to be prescribed and monitored under the care of a healthcare professional due to their potential side effects and interactions with other drugs. Furthermore, since CareXPS doesn't provide care for psychotic or bipolar disorders, these conditions are excluded from the provided list.",
    onlyDesc: null,
    info: [
      {
        text: "Nutritional psychiatry is an emerging field that delves into the interplay between dietary patterns, nutrition, and mental health. Recent advancements have brought to light the role of epigenetics, which refers to changes in gene expression without altering the underlying DNA sequence. Diet can influence these epigenetic marks, potentially affecting mental health.",
      },
      {
        heading: "Benefits:",
        points: [],
      },
      {
        title: "Personalized Interventions",
        text: "Epigenetic markers can provide insights into how individual dietary choices may impact mental well-being. This allows for more tailored nutritional recommendations to enhance mental health outcomes.",
      },
      {
        title: "Holistic Approach",
        text: "Integrating epigenetic-driven nutritional psychiatry can offer a comprehensive method of mental health care, considering both genetic and environmental factors.",
      },
      {
        title: "Prevention and Management",
        text: "Identifying dietary factors that influence epigenetic changes can assist in both preventing certain mental health conditions and managing existing ones, reducing reliance solely on pharmacological interventions.",
      },
      {
        heading: "Incorporating into CareXPS:",
        points: [],
        note: "Embedding this innovative approach into CareXPS' mental wellness initiative, we have done the following.",
      },
      {
        title: "Training and Development",
        text: "We ensure that our team is well-versed in the fundamentals of epigenetics and its role in nutritional psychiatry.",
      },
      {
        title: "Collaboration",
        text: "We partner with nutritional experts specializing in epigenetics to offer a well-rounded care package.",
      },
      {
        title: "Assessment Tools",
        text: "We will integrate epigenetic testing and assessment into the initial patient evaluations to guide personalized nutritional recommendations if needed. Additional costs will be associated with these other measures.",
      },
      {
        title: "Educational Workshops",
        text: "We provide educational workshops for patients, emphasizing the importance of nutrition in mental health and how epigenetics plays a pivotal role.",
      },
      {
        title: "Ongoing Research",
        text: "We encourage and invest in research to stay updated with the latest advancements and validate the efficacy of epigenetic-driven nutritional interventions.",
      },
    ],
    // slugText2:
    //   "Incorporating epigenetic-driven nutritional psychiatry can position CareXPS at the forefront of holistic mental health care, offering patients an integrated and personalized path to mental wellness.",
  },
];
export const contact_details = [
  {
    title: "Address",
    details: "1450 O’Connor Drive, Suite 16 East York, ON, M4B 2T8",
  },
  {
    title: "Phone",
    details: "(437) 880-7689",
  },
  {
    title: "Fax",
    details: "(437) 291-1495",
  },
  {
    title: "Email",
    details: "info@carexps.com",
  },
];
