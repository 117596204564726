import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/MainPage/main.module.css";
import { discriptions_list, featured_items } from "../../../helper/Helper";

const SectionOne = () => {
  const videoRef = useRef(0);
  const [shouldMute, setShouldMute] = useState(true);

  const playVideo = () => {
    if (shouldMute) {
      setShouldMute(false);
      videoRef?.current?.play();
    } else {
      setShouldMute(true);
      videoRef?.current?.pause();
    }
  };

  return (
    <div className={styles.section1}>
      <div className={styles.contentWrapper}>
        <Row className={styles.contentRow}>
          <Col md="6" className={styles.contentRowCol1}>
            <div className={styles.section1BgLayer}>
              <div className={styles.textureWrapper} />
            </div>
            <div className={styles.leftContentWrapper}>
              <div className={styles.h1Text}>Ready. <span >Set.</span> Care!</div>
              <div className={styles.h3Text}>
                Book your appointment today with a Canadian-licensed physician
                and experience personalized, cutting-edge medicine.
              </div>
              <button className={styles.gsnButton}>
                <a
                  target="blank"
                  href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=fee48727-4828-402b-8293-103f7f1a87d0#/online-booking"
                >
                  Get Started Now
                </a>
              </button>
              <div className={styles.featuredWrapper}>
                <div className={styles.featuredHeading}>Featured</div>
                <div className={styles.featuredItemWrapper}>
                  {featured_items?.map((item, index) => {
                    let _itemData = {
                      name: item,
                    };
                    return (
                      <Link to="/detailPage" state={_itemData} key={index}>
                        <div
                          className={styles.featuredItem}
                          onClick={() => console.log(item)}
                        >
                          {item}
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" className={styles.contentRowCol2}>
            <video
              onPause={() => playVideo()}
              onTouchEnd={() => playVideo()}
              autoPlay
              muted={shouldMute}
              ref={videoRef}
              className={styles.section1Video}
              controls
            >
              <source
                type="video/mp4"
                src={require("../../../assets/intro.mp4")}
              />
            </video>
          </Col>
        </Row>
      </div>
      {/* Section 1 ends here */}
    </div>
  );
};

export default SectionOne;
