import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../../../css/MainPage/main.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { treatmentMenu, treatment_list } from "../../../helper/Helper";
import { FiChevronRight } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";

const SectionTwo = () => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <div id="ac" className={styles.sectionTwoContainer}>
      <div className={styles.sectionTwoInnerCont}>
        <div className={styles.sectionHeadingWrapper}>
          <div className={styles.sectionHeading}>Explore our treatments</div>
          <div className={styles.sectionHeadingSlogan}>
            Select a condition to get started.
          </div>
        </div>
        {/* Menu starts here */}
        <div className={styles.sectionThreMenuWrapper}>
          <Row className={styles.sectionThreMenuRow}>
            {treatmentMenu?.map((item, index) => {
              let _styles =
                activeItem == index
                  ? styles.sectionThreMenuItemActive
                  : styles.sectionThreMenuItem;
              return (
                <Col sm="2" key={index} className={styles.sectionThreMenuCol}>
                  <HashLink
                    to={`#${item?.to}`}
                    className={_styles}
                    onClick={() => setActiveItem(index)}
                  >
                    {item?.title}
                  </HashLink>
                </Col>
              );
            })}
          </Row>
        </div>
        {/* Treatment serction starts here */}

        {treatment_list?.map((item, index) => {
          return (
            <div className={styles.treatmentSection} key={index}>
              <div id={item?.to} className={styles.treatmentCategory}>
                {item?.category}
              </div>
              {/* Treatment Row starts here */}
              <Row className={styles.treatmentItemWrapper}>
                {item?.items?.map((item, index) => {
                  item["key"] = index + 1;
                  return (
                    <Col md="4" key={index} className={styles.treatmentItemColSize}>
                      <Link to="/detailPage" state={item}>
                        <div className={styles.treatmentItem}>
                          <div className={styles.treatmentIconWrapper}>
                            <img
                              className={styles.treatmentIcon}
                              src={item?.icon}
                              alt=""
                            />
                          </div>
                          <div className={styles.treatmentTextWrapper}>
                            <div className={styles.treatmentTitle}>
                              {item?.name}
                            </div>
                            {/* {item?.slogan && (
                              <div className={styles.treatmentSlogan}>
                                {item?.slogan}
                              </div>
                            )} */}
                          </div>
                          <div className={styles.rightArrow}>
                            <FiChevronRight />
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
              {/* Treatment Row ends here */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionTwo;
